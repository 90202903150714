<template>
  <div class="baners">
    <div class="baners__header">
      <div class="baners__header_title">Акции</div>
      <div class="baners__header_button pointer" @click="openAddMenu">Добавить
        <img src="@/assets/add_box_white.svg" alt="">
      </div>
    </div>
    <action-and-partner-list @update="openUpdateMenu" @reorder="reorderPartners" :items="partnerList" :is-loading="isLoading"></action-and-partner-list>

    <right-menu
        :is-open="isOpenMenu"
        :submitBtnValue="rightMenu.submitBtnValue"
        @submit="submitHandler"
        :show-message="showMessage"
        @close-menu="closeRightMenu">
      <template #header>
        {{ rightMenu.headerTitle }}
      </template>
      <template #content>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Изображение</div>
          <div class="d-flex w100 justify-center">
            <div style="width: 100%; height: 100px;">
              <v-image-input v-model="selectedPartner.img">
                <template #empty-layout>
                  <div class="image-layout">
                    Нажмите или перетащите изображение
                  </div>
                </template>
              </v-image-input>
            </div>
          </div>
        </div>

        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Описание</div>
          <textarea v-model="selectedPartner.description" class="right-menu__content__item-textarea"></textarea>
        </div>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Видимость</div>
          <base-switch v-model="selectedPartner.visible" small/>
        </div>
      </template>
    </right-menu>
  </div>
</template>

<script>
import RightMenu from "@/components/RightMenu";
import VImageInput from 'vue3-img-input';
import {useActions} from "@/composition/useActions";
import BaseSwitch from "../../../components/BaseSwitch";
import ActionAndPartnerList from "../../../components/ActionAndPartnerList";

const {actions, getActions, reorderActions, createAction, updateActionById, changeActionImg} = useActions()


export default {
  name: "ActionsView",
  components:{
    BaseSwitch,
    ActionAndPartnerList,
    RightMenu,
    VImageInput,
  },
  data() {
    return {
      isOpenMenu: false,
      selectedPartner: {},
      ildImg: null,
      rightMenu: {
        type: "add",
        headerTitle: "Добавить акцию",
        submitBtnValue: "Добавить",
      },
      partnerList: actions,
      showMessage: false,
      isLoading: true,
    }
  },
  async created() {
    await this.updatePartnerList()
  },
  methods: {
    async updatePartnerList() {
      this.isLoading = true
      getActions()
    },
    async submitHandler() {
      if (this.rightMenu.type === "add") {
        await this.createPartner()
      } else if (this.rightMenu.type === "edit") {
        await this.updatePartner()
      }
    },
    closeRightMenu() {
      this.isOpenMenu = false
    },
    async reorderPartners(newOrder) {
      this.isLoading = true
      reorderActions(newOrder).finally(() => {
        this.updatePartnerList()
      })
    },
    async createPartner() {
      const {img, ...toCreate} = {...this.selectedPartner}

      const id = await createAction(toCreate).then(r => r.data.data.id)
      if (img) {
        await changeActionImg(id, img)
      }

      await this.updatePartnerList()
      this.selectedPartner = {}
      this.closeRightMenu()
    },
    async updatePartner() {
      const {id, img, ...toUpdate} = {...this.selectedPartner}

      if (!toUpdate.description) {
        this.showMessage = !this.showMessage
        return
      }
      let imgUpdate = false
      if (this.ildImg !== img) {
        imgUpdate = true
      }


      await updateActionById(id, { ...toUpdate })
      if (imgUpdate) {
        await changeActionImg(id, img)
      }

      await this.updatePartnerList()
      this.selectedPartner = {}
      this.closeRightMenu()
    },
    openAddMenu() {
      this.selectedPartner = {
        description: null,
        img: null,
        visible: true,
      }
      this.rightMenu = {
        type: "add",
        headerTitle: "Добавить акцию",
        submitBtnValue: "Добавить",
      }
      this.isOpenMenu = true
    },
    async openUpdateMenu(partnerToUpdate) {
      this.rightMenu = {
        type: "edit",
        headerTitle: "Изменить акцию",
        submitBtnValue: "Сохранить",
      }
      this.ildImg = partnerToUpdate.img
      this.selectedPartner = { ...partnerToUpdate }
      this.isOpenMenu = true
    },
  },
  watch: {
    partnerList() {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/profile/stock/baners.scss";
@import "vue3-img-input/src/styles/style.scss";

.warning {
  border: 1px solid rgba(red, 0.5);
}
</style>