
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useArticle() {

    const { api, params, store_id } = useApi()


    const articleList = ref()
    const article = ref()

    const getArticleList = () => {
        return axios.get(api + `/cp/stores/${store_id.value}/articles/`, params)
            .then((r) => {
                articleList.value = r.data.data
            })
    }
    const getArticleById = (articleId) => {
        return axios.get(api + `/cp/articles/${articleId}/`,  params)
            .then((response) => {
                article.value = response.data.data
            })
    }
    const createArticle = (payload) => {
        return axios.post(api + `/cp/stores/${store_id.value}/articles/`, payload, params)
    }
    const updateArticle = (articleId, payload) => {
        return axios.put(api + `/cp/articles/${articleId}/`, payload, params)
    }
    const deleteArticle = (articleId ) => {
        return axios.delete(api + `/cp/articles/${articleId}/`, params)
    }

    return {
        getArticleList,
        getArticleById,
        createArticle,
        updateArticle,
        deleteArticle,
        articleList,
        article,
    }
}