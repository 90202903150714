<template>
  <div class="managers">
    <div class="managers__header">
      <div class="managers__header_title">Руководители</div>
      <div class="managers__header_button pointer" @click="openAddMenu">Добавить
        <img src="@/assets/add_box_white.svg" alt="">
      </div>  
    </div>
      <template v-if="managerList?.length">
        <ul class="managers__list d-flex w100 flex-wrap gap-16">
          <li v-for="manager in managerList" :key="manager.id" class="managers__list__item d-flex flex-column gap-4 space-between">
            <div class="d-flex flex-column gap-4">
              <img :src="manager.img" class="managers__list__item-image" />
              <div class="managers__list__item-title">
                {{ manager.name }}
              </div>
              <div class="managers__list__item-position">
                {{ manager.position }}
              </div>   
            </div>         
            <div class="managers__list__item__actions d-flex justify-center align-center">
              <div @click="openUpdateMenu(manager)" class="managers__list__item__actions-update" >Изменить</div>
              <img @click="deleteManager(manager.id)" style="background: #FFFFFF; border-top: 1px solid #F2623F; padding: 2.3px 2.8px; border-radius: 0px" class="pointer" src="@/assets/delete-red.svg" alt="">
            </div>
          </li>
        </ul>
      </template>

    <right-menu
        :is-open="isOpenMenu"
        :submitBtnValue="rightMenu.submitBtnValue"
        @submit="submitHandler"
        @close-menu="closeRightMenu">
      <template #header>
        {{ rightMenu.headerTitle }}
      </template>
      <template #content v-if="Object.keys(selectedManager).length">
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Фото</div>
          <div class="d-flex w100 justify-center">
            <v-image-input v-model="selectedManager.img">
              <template #empty-layout>
                <div class="image-layout">
                  Нажмите или перетащите изображение
                </div>
              </template>
            </v-image-input>
          </div>
        </div>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Имя</div>
          <input v-model="selectedManager.name" placeholder="Наименование" class="right-menu__content__item-input"/>
        </div>

        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Должность</div>
          <input v-model="selectedManager.position" placeholder="Должность" class="right-menu__content__item-input"/>
        </div>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Образование</div>
          <textarea v-model="selectedManager.description" placeholder="Образование" class="right-menu__content__item-textarea"></textarea>
        </div>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">О карьере</div>
          <textarea v-model="selectedManager.career_description" placeholder="О карьере" class="right-menu__content__item-textarea"></textarea>
        </div>
      </template>
    </right-menu>
  </div>
</template>

<script>
import RightMenu from "@/components/RightMenu";
import VImageInput from "vue3-img-input";

import { useManagers } from '@/composition/useManagers.js'

const {managerList, getManagerList, createManager, updateManagerById, deleteManagerById, createManagerImgById} = useManagers()

export default {
  name: 'ManagersView',
  components:{
    RightMenu,
    VImageInput,
  },

  data() {
    return {
      isOpenMenu: false,
      selectedManager: {},
      managerList: [],
      rightMenu: {
        type: "add",
        headerTitle: "Добавить руководителя",
        submitBtnValue: "Добавить",
      },
    }
  },
  async created() {
    await this.updateManagerList()
  },
  methods: {
    async updateManagerList() {
      await getManagerList()
      this.managerList = managerList
    },
    async submitHandler() {
      if (this.rightMenu.type === "add") {
        await this.createManager({ ...this.selectedManager })
      } else if (this.rightMenu.type === "edit") {
        await this.updateManager({ ...this.selectedManager })
      }
    },
    closeRightMenu() {
      this.isOpenMenu = false
    },
    async createManager(manager) {
      const { img, ...toCreate } = manager
      await createManager({ ...toCreate }).then(async (r) => {
        if (img) {
          await createManagerImgById(r.data.data.id, img)
        }
      })

      await this.updateManagerList()
      this.selectedManager = {}
      this.closeRightMenu()
    },
    async deleteManager(id) {
      await deleteManagerById(id)
      await this.updateManagerList()
    },
    async updateManager(manager) {
      const { id, img, ...toUpdate } = { ...manager }
    
      if (this.managerList.find(manager => manager.id === id).img !== img) {
        await createManagerImgById(id, img)
      }
      await updateManagerById(id, { ...toUpdate })

      await this.updateManagerList()
      this.selectedManager = {}
      this.closeRightMenu()
    },
    openAddMenu() {
      this.selectedManager = {
        name: null,
        position: null,
        description: null,
        career_description: null,
      }
      this.rightMenu = {
        type: "add",
        headerTitle: "Добавить руководителя",
        submitBtnValue: "Добавить",
      }
      this.isOpenMenu = true
    },
    async openUpdateMenu(toUpdate) {
      this.rightMenu = {
        type: "edit",
        headerTitle: "Изменить руководителя",
        submitBtnValue: "Сохранить",
      }

      this.selectedManager = { ...toUpdate }

      this.isOpenMenu = true
    },
  },
}
</script>

<style lang="scss">
  @import "@/styles/profile/informations/managers.scss";
  @import "vue3-img-input/src/styles/style.scss";
</style>