
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function usePartners() {

    const { api, params, Token } = useApi()

    const partners = ref()

    const getPartners = () => {
        return axios.get(api + `/cp/partners/`, params)
            .then((r) => {
                partners.value = [ ...r.data.data ].map(partners => {
                    return partners
                })
                return [ ...partners.value ]
            })
    }
    const createPartner = (payload) => {
        return axios.post(api + `/cp/partners/`, payload, params)
    }
    const changePartnerImg = (id, img) => {
        const formData = new FormData()
        formData.append('img',  img)
        const config = {
            headers: {
                "Authorization": Token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.put(api + `/cp/partners/${id}/img/`, formData, config)
    }

    const updatePartnerById = (id, payload) => {
        return axios.put(api + `/cp/partners/${id}/`, payload, params)
    }
    const reorderPartners = (payload) => {
        return axios.put(api + `/cp/partners/order/`, payload, params)
    }

    return {
        getPartners,
        createPartner,
        updatePartnerById,
        changePartnerImg,
        reorderPartners,
        partners,
    }
}