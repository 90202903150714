<template>
  <div @click="clickHandler" class="label">
    <input
        type="checkbox"
        class="ios-switch"
        :class="{ checked: modelValue, duo: duo, normal: !small }"
    />
    <div><div></div></div>
  </div>
</template>

<script>
export default {
  name: 'BaseSwitch',
  props: {
    small: Boolean,
    duo: Boolean,
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler() {
      this.$emit('update:modelValue', !this.modelValue)
    },
  },
}
</script>

<style scoped lang="scss">
.label {
  display: inline-flex;
  width: fit-content;
  cursor: pointer;
}
input[type='checkbox'].ios-switch.duo + div {
  background-color: #e08167;
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

/* small Track */
input[type='checkbox'].ios-switch + div {
  width: 40px;
  height: 22px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color, box-shadow, 0.4s;
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.2);
}

input[type='checkbox'].ios-switch.checked + div {
  width: 40px;
  background-position: 0 0;
  background-color: #F2623F;
  border: 1px solid #F2623F;
  box-shadow: inset 0 0 0 10px #F2623F;
}

input[type='checkbox'].normal.ios-switch + div {
  width: 50px;
  height: 29px;
}

/* small  */
input[type='checkbox'].ios-switch + div > div {
  float: left;
  width: 18px;
  height: 18px;
  border-radius: inherit;
  background: #ffffff;
  transition: transform, background-color, box-shadow,
  0.3s cubic-bezier(0.54, 1.85, 0.5, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
}

input[type='checkbox'].ios-switch.checked + div > div {
  transform: translate3d(18px, 0, 0);
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(#F2623F, 1);
}

/* Big Knob */
input[type='checkbox'].normal.ios-switch + div > div {
  width: 23px;
  height: 24px;
  margin-top: 1px;
}

/* Checked Big Knob (Blue Style) */
input[type='checkbox'].normal.ios-switch.checked + div > div {
  transform: translate3d(23px, 0, 0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(#F2623F, 1);
}

@media (max-width: 767.98px) {
  input[type='checkbox'].ios-switch + div > div {
    width: 14px;
    height: 14px;
  }
  input[type='checkbox'].ios-switch + div {
    width: 36px;
    height: 18px;
  }
}
</style>
