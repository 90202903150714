<template>
  <div class="baners">
    <div class="baners__header">
      <div class="baners__header_title">Банеры</div>
      <div class="baners__header_button pointer" @click="openAddMenu">Добавить
        <img src="@/assets/add_box_white.svg" alt="">
      </div>
    </div>
      <template v-if="categoryList && productList && bannerList && articleList">
        <BanersList
            :banner-list="bannerList"
            :category-list="categoryList"
            :product-list="productList"
            :article-list="articleList"
            @delete-banner="updatePartnerList"
            @open-change-banner-menu="openUpdateMenu" />
      </template>

    <right-menu
        :is-open="isOpenMenu"
        :submitBtnValue="rightMenu.submitBtnValue"
        @submit="submitHandler"
        :show-message="showMessage"
        @close-menu="closeRightMenu">
      <template #header>
        {{ rightMenu.headerTitle }}
      </template>
      <template #content>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Изображение</div>
          <div class="d-flex w100 justify-center">
            <div  style="width: 100%; height: 100px;">
              <v-image-input v-model="selectedBanner.img">
                <template #empty-layout>
                  <div class="image-layout">
                    Нажмите или перетащите изображение
                  </div>
                </template>
              </v-image-input>
            </div>
          </div>
        </div>

        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title required">Наименование</div>
          <input v-model="selectedBanner.name" placeholder="Наименование" class="right-menu__content__item-input" :class="{warning: !selectedBanner.name}"/>
        </div>

        <transition name="height">
          <div v-if="selectedBanner.link || isAllTargetsEmpty" class="right-menu__content__item">
            <div class="right-menu__content__item-title">Ссылка</div>
            <input v-model="selectedBanner.link" placeholder="Ссылка" class="right-menu__content__item-input"/>
          </div>
        </transition>

        <transition name="height">
          <div v-if="selectedBanner?.article?.body || isAllTargetsEmpty" class="right-menu__content__item">
            <div class="right-menu__content__item-title">Статья</div>
            <textarea v-model="selectedBanner.article.body" class="right-menu__content__item-textarea"></textarea>
          </div>
        </transition>

        <transition name="height">
          <div v-if="selectedBanner.category?.id || isAllTargetsEmpty" class="right-menu__content__item">
            <div class="right-menu__content__item-title">Категория</div>
            <v-select v-model="selectedBanner.category" :options="categoryList" label="name"></v-select>
          </div>
        </transition>

        <transition name="height">
          <div v-if="selectedBanner.product?.id || isAllTargetsEmpty" class="right-menu__content__item">
            <div class="right-menu__content__item-title">Продукт</div>
            <v-select v-model="selectedBanner.product" :options="productList" label="name"></v-select>
          </div>
        </transition>

        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Дата начала</div>
          <Datepicker
              v-model="selectedBanner.start_at"
              autoApply
              :clearable="false"
              :enableTimePicker="false"
          ></Datepicker>
        </div>

        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Дата окончания</div>
          <Datepicker
              v-model="selectedBanner.end_at"
              autoApply
              :clearable="false"
              :enableTimePicker="false"
          ></Datepicker>
        </div>
      </template>
    </right-menu>
  </div>
</template>

<script>
import BanersList from '@/components/Stock/BanersList'
import RightMenu from "@/components/RightMenu";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import VImageInput from 'vue3-img-input';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {useProduct} from "@/composition/useProduct";
import {useStock} from "@/composition/useStock";
import {useArticle} from "@/composition/useArticle";

const {getCategories, getProducts, categories, products} = useProduct()
const {getBaners, changeBaner, baners} = useStock()
const {getArticleList, articleList, article, getArticleById, createArticle, updateArticle } = useArticle()

const {createBaner, changeBanerImg} = useStock()

export default {
  components:{
    BanersList,
    RightMenu,
    vSelect,
    VImageInput,
    Datepicker,
  },

  data() {
    return {
      isOpenMenu: false,
      selectedBanner: {},
      oldBannerImg: null,
      rightMenu: {
        type: "add",
        headerTitle: "Добавить баннер",
        submitBtnValue: "Добавить",
      },
      bannerList: null,
      articleList: null,
      productList: null,
      categoryList: null,
      showMessage: false,
    }
  },
  computed: {
    isAllTargetsEmpty () {
      return !this.selectedBanner.link
          && !this.selectedBanner.article?.body
          && !this.selectedBanner.category
          && !this.selectedBanner.product
          && this.isOpenMenu
    }
  },
  async created() {
    await this.updatePartnerList()
  },
  methods: {
    async updatePartnerList() {
      await Promise.all([getCategories(), getProducts(), getArticleList(), getBaners()])

      this.bannerList = baners.value
      this.articleList = articleList.value
      this.productList = products.value
      this.categoryList = categories.value
    },
    async submitHandler() {
      if (this.rightMenu.type === "add") {
        await this.createNewBanner()
      } else if (this.rightMenu.type === "edit") {
        await this.updateBanner()
      }
    },
    closeRightMenu() {
      this.isOpenMenu = false
    },
    async createNewBanner() {
      const toCreate = { ...this.selectedBanner }
      const {img} = {...toCreate}

      if (!toCreate.name) {
        this.showMessage = !this.showMessage
        return
      }

      delete toCreate.img
      toCreate.start_at = Math.round(toCreate.start_at.getTime() / 1000)
      toCreate.end_at = Math.round(toCreate.end_at.getTime() / 1000)
      if (toCreate.category?.id) {
        toCreate.category = toCreate.category.id
      }
      if (toCreate.product?.id) {
        toCreate.product = toCreate.product.id
      }

      if (this.selectedBanner.article.body) {
        toCreate.article = await createArticle({
          title: toCreate.name,
          body: toCreate.article.body
        }).then(response => response.data.data.id)
      } else {
        toCreate.article = null
      }

      const id = await createBaner(toCreate).then(r => r.data.data.id)
      if (img) {
        await changeBanerImg(id, img)
      }

      await this.updatePartnerList()
      this.selectedBanner = {}
      this.closeRightMenu()
    },
    async updateBanner() {
      const bannerToUpdate = { ...this.selectedBanner }
      const { id }= { ...bannerToUpdate }

      if (!bannerToUpdate.name) {
        this.showMessage = !this.showMessage
        return
      }
      let img = null
      let imgUpdate = false
      if (this.oldBannerImg !== bannerToUpdate.img) {
        imgUpdate = true
        img = bannerToUpdate.img
      }
      delete bannerToUpdate.img
      delete bannerToUpdate.id
      bannerToUpdate.start_at = bannerToUpdate.start_at.getTime() / 1000
      bannerToUpdate.end_at = bannerToUpdate.end_at.getTime() / 1000
      if (bannerToUpdate.category?.id) {
        bannerToUpdate.category = bannerToUpdate.category.id
      }
      if (bannerToUpdate.product?.id) {
        bannerToUpdate.product = bannerToUpdate.product.id
      }
      if (bannerToUpdate.article) {
        if (bannerToUpdate.article?.body) {
          if (bannerToUpdate.article?.id) {
            await updateArticle(bannerToUpdate.article.id, {
              title: bannerToUpdate.name, body: bannerToUpdate.article.body
            })
            bannerToUpdate.article = bannerToUpdate.article.id
          } else  {
            bannerToUpdate.article = await createArticle({
              title: bannerToUpdate.name,
              body: bannerToUpdate.article.body
            }).then(response => response.data.data.id)
            console.log(bannerToUpdate)
          }
        } else {
          bannerToUpdate.article = null
        }
      }


      await changeBaner(id, { ...bannerToUpdate })
      if (imgUpdate) {
        await changeBanerImg(id, img)
      }

      await this.updatePartnerList()
      this.selectedBanner = {}
      this.closeRightMenu()
    },
    openAddMenu() {
      this.selectedBanner = {
        name: null,
        img: null,
        link: null,
        category: null,
        product: null,
        article: {
          body: null,
        },
        activated: true,
        start_at: new Date(),
        end_at: new Date(),
      }
      this.rightMenu = {
        type: "add",
        headerTitle: "Добавить баннер",
        submitBtnValue: "Добавить",
      }
      this.isOpenMenu = true
    },
    async openUpdateMenu(bannerToUpdate) {
      this.rightMenu = {
        type: "edit",
        headerTitle: "Изменить баннер",
        submitBtnValue: "Сохранить",
      }
      this.oldBannerImg = bannerToUpdate.img
      this.selectedBanner = { ...bannerToUpdate }
      this.selectedBanner.start_at = new Date(this.selectedBanner.start_at  * 1000)
      this.selectedBanner.end_at = new Date(this.selectedBanner.end_at  * 1000)
      if (!this.selectedBanner.category?.id) {
        this.selectedBanner.category = null
      }
      if (!this.selectedBanner.product?.id) {
        this.selectedBanner.product = null
      }
      if (this.selectedBanner.article?.id && !this.selectedBanner.article?.body) {
        await getArticleById(this.selectedBanner.article.id)
        this.selectedBanner.article = { ...article.value }
      }

      this.isOpenMenu = true
    },
  },
}
</script>

<style lang="scss">
  @import "@/styles/profile/stock/baners.scss";
  @import "vue3-img-input/src/styles/style.scss";

  .warning {
    border: 1px solid rgba(red, 0.5);
  }
</style>