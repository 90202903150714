<template>
  <div class="baners-list">
    <div class="baners-list__titles">
      <div class="baners-list__titles_img">Изображение</div>
      <div style="margin-left: 16px; display: flex; flex-grow: 1">Описание</div>
      <div class="baners-list__titles_controls">Видимость</div>
      <div class="baners-list__titles_controls">Управление</div>
    </div>
  </div>
  <draggable
      :list="items"
      item-key="id"
      class="baners-list-items"
      ghost-class="ghost"
      @end="dragEnd"
  >
    <template #item="{ element }">
      <div class="baners-list-items__item" style="cursor: pointer">

        <!-- <div class="baners-list-items__item_img" v-if="!baner.img">img</div> -->
        <img :src="element.img" class="baners-list-items__item_img">

        <div style="margin-left: 16px; display: flex; flex-grow: 1">{{element.description}}</div>

        <div class="baners-list-items__item_controls" style="display: flex; justify-content: center">
          <img v-if="element.visible" src="@/assets/visibility.svg" class="baners-list-items__item_controls-show" >
          <img v-else src="@/assets/visibility_off.svg" class="baners-list-items__item_controls-unshow" >
        </div>
        <div class="baners-list-items__item_controls" style="display: flex; justify-content: center">
          <img src="@/assets/settings.svg" class="baners-list-items__item_controls-changed" @click="$emit('update', element)">
        </div>
      </div>
    </template>
  </draggable>
  <base-loader :is-open="isLoading" />
</template>

<script>
import BaseLoader from "./BaseLoader";
import draggable from "vuedraggable";
export default {
  name: 'ActionAndPartnerList',
  components: {BaseLoader, draggable},
  emits: ['update', 'reorder'],
  props: {
    items: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      orderList: [],
    }
  },
  methods: {
    dragEnd(e) {
      this.insert(e.newIndex, e.oldIndex)
      this.onReorderProducts(this.orderList)
    },
    insert(newIndex, oldIndex) {
      const tmp = this.orderList[oldIndex]
      this.orderList.splice(oldIndex, 1)
      this.orderList.splice(newIndex, 0, tmp)
    },
    async onReorderProducts(newOrderList) {
      this.$emit('reorder', {order: newOrderList})
    }
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        if (this.items?.length) {
          this.orderList = this.items.map(item => item.id)
        }
      }
    }
  },
}
</script>

<style lang="scss" src="@/styles/profile/stock/baners-list.scss"></style>