<template>
<div class="admin" v-if="admin">
    <div class="admin__header">
        <div class="admin__header-logo">
            <img src="@/assets/user.png" alt="" class="admin__header-logo_img">
        </div>
        <div class="admin__header-info">
            <div class="admin__header-info-item">
                <div class="admin__header-info-item_title">Имя: </div>
                <input type="text" v-model="admin.name" class="admin__header-info-item_email">
            </div>

            <div class="admin__header-info-item">
                <div class="admin__header-info-item_value">Email: </div>
                <input type="text" v-model="admin.email" class="admin__header-info-item_email">
            </div>

            <div class="admin__header-info-item">
                <div class="admin__header-info-item_value">Телефон: </div>
                <input type="text" v-model="admin.tel" class="admin__header-info-item_email">
            </div>
        </div>
        <div class="admin__header-buttons">
            <div class="admin__header-buttons_save pointer">Сохранить</div>
        </div>
    </div>
</div>
</template>

<script>
import {onMounted, watch, ref} from "vue"
import {
    useAdmin
} from "@/composition/useAdmin.js"
export default {

    setup() {
        const {getProfile} = useAdmin()
        const admin = ref()
        onMounted(() => {
            getProfile()
            .then((r) => {
                admin.value = r.data.data
            })
        })

        const updateDataAdmin = () => {

        }
        return{
            admin,

            updateDataAdmin,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/admin.scss"></style>
