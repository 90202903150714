
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useContacts() {

    const { api, params, store_id } = useApi()
    const contacts = ref()
    const getContacts = () => {
        return axios.get(api + `/cp/stores/${store_id.value}/contacts/`, params)
        .then((r) => {
            contacts.value = r.data.data
        })
    }
    const putContact = (body, contact_id) => {
        return axios.put(api + `/cp/contacts/${contact_id}/`, body, params)
    }
    const createContact = (body) => {
        return axios.post(api + `/cp/stores/${store_id.value}/contacts/`, body, params)
    }
    const deleteContact = (contact_id) => {
        return axios.delete(api + `/cp/contacts/${contact_id}/`, params)
    }
    return {
        contacts,
        
        getContacts,
        putContact,
        createContact,
        deleteContact,
    }
}