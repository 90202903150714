<template>
    <div class="categories-list">
        <div class="categories-list__column" :class="{'categories-list__column-active': attributes}">
            <div class="categories-list__nesting">
            <div class="categories-list__titles" :class="{'categories-list__titles-active': attributes}">
                <div class="categories-list__titles_img">Изображение</div>
                <div class="categories-list__titles_name">Наименование</div>
                <div class="categories-list__titles_controls"></div>
            </div>
            <draggable
                :list="categories"
                item-key="name"
                class="list-group"
                ghost-class="ghost"
                @end="dragEnd"
            >
                <template #item="{ element }">
                    <div class="list-group-item" style="border-bottom: 1px solid #333;" >
                        <div class="categories-list__item pointer" @click="getAttr(element.id)">
                            <img class="categories-list__item_img" :src="element.thumbnail" v-if="element.thumbnail">
                            <img class="categories-list__item_img" alt="ОТСУТСТВУЕТ" v-else>
                            <div class="categories-list__item_name">{{ element.name }}</div>
                            <div class="categories-list__item_controls">
                                <img src="@/assets/visibility.svg" class="categories-list__item_controls-show" style="display: none">
                                <img src="@/assets/visibility_off.svg" class="categories-list__item_controls-unshow" style="display: none">
                                <img src="@/assets/settings.svg" class="categories-list__item_controls-changed" @click="openChangeMenu(element)">
<!--                                <img src="@/assets/menu_white_imp.svg" class="categories-list__item_controls-arrow" @click="attributes = true">-->
                                <img src="@/assets/delete.svg" class="categories-list__item_controls-delete" @click="del(element.id)">
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
            </div>
        </div>

          <div class="categories-list__settings" :class="{'categories-list__settings-active': attributes}">
              <div class="categories-list__header-attr">
                <div class="categories-list__header-attr_title">Атрибуты</div>
                <img class="pointer" @click="attributes = false" style="position: absolute; right: 0; margin-right: 12px; margin-top: -10px; transform: rotate(45deg); background: red; border-radius: 50%; width: 20px" src="@/assets/add_white.svg" alt="">
              </div>
              <div class="categories-list__able-attr" v-if="categoryId">
                <div class="categories-list__able-attr_item" v-for="(able_attr, index) in able_attrs" :key="index">
                  {{able_attr}}
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>
                </div>
              </div>
              <div class="categories-list__new-attr" v-if="attrCategoryes && categoryId">
                <div class="categories-list__new-attr_item" v-for="(new_attr, index) in attrCategoryes[0].attrs" :key="index">
                  <div style="display: flex; align-items: center; justify-content: space-between; width: 100%">
                    <div class="categories-list__new-attr_item-name">{{new_attr.name}}</div>
                    <div v-if="index > 2" class="categories-list__new-attr_item-buttons">
                      <img class="categories-list__item_controls-changed-attr pointer" src="@/assets/settings.svg" @click="updateAttr(new_attr)">
                      <svg class="categories-list__item_controls-delete-attr pointer" @click="delAttr(new_attr.id)" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>
                    </div>
                  </div>
                  <div style="width: 100%; display: flex;">
                    <div class="categories-list__new-attr_item-checked" v-if="!new_attr.optional">Обязательно</div>
                    <div class="categories-list__new-attr_item-checked" v-else>Необязательно</div>
                  </div>
                </div>
              </div>

              <div class="categories-list__reset" v-if="changeId">
                <img class="categories-list__reset_button" src="@/assets/add_white.svg" @click="reset">
              </div>
              <div class="categories-list__button" v-if="categoryId">
                <div class="categories-list__input-add">
                  <div class="categories-list__completion">
                    <input type="text" class="categories-list__input-add_input" placeholder="Имя атрибута" v-model="nameAttr">
                    <div class="categories-list__optional">
                      <div class="categories-list__optional_item-checked">Обязательное
                        <div class="categories-list__optional_check" @click="optionalActive" :class="{'categories-list__optional_check-select': optional}">
                          <img style="width: 20px" src="@/assets/done_white_24dp.svg" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="categories-list__arrow" @click="addAttr" v-if="!changeId">
                    <img class="categories-list__input-add_arrow" src="@/assets/add_white.svg" alt="">
                  </div>

                  <div class="categories-list__arrow-change" @click="changeAttr" v-else>
                    <img class="categories-list__input-add_arrow" style="transform: rotate(-90deg)" src="@/assets/arrow.svg" alt="">
                  </div>
                </div>
              </div>
          </div>
      <base-loader :is-open="isLoading" />
    </div>
</template>

<script>
import {onMounted, ref, watch} from "vue"
import {useProduct} from "@/composition/useProduct.js"

//Drag and drop
import draggable from 'vuedraggable'
import Tree from "@/components/other/Trees.vue"
import BaseLoader from "@/components/BaseLoader";

export default {
    props: {
        categoriesData: Object
    },
    components: {
      BaseLoader,
        //drag and drop
            draggable,
            Tree,
        //drag and drop
    },

    data() {
    return {
      attributes: false,
      able_attrs: [
        "Имя", "Метка", "Цена", "Наличие шт.", "Категория"
      ],
    };
  },
  methods: {
  },
    
    setup(props, {emit}) {
        /* const categories = ref() */
        const {deleteCategory, reorderCategories, categories, getCategories, getAttrCategory, attrCategoryes, addAttrCategory, deleteAttrCategory, changeAttrCategory} = useProduct()
        const del = (id) => {
            deleteCategory(id)
            .then(() => {
                getCategories()
            })
        }

        const optional = ref(false)
        const optionalActive = () => {
          optional.value = !optional.value
        }
        const nameAttr = ref("")
        const categoryId = ref(null)

        const addAttr = () => {
          addAttrCategory(categoryId.value, {
            name: nameAttr.value,
            optional: !optional.value,
          })
          .then(() => {
            getAttrCategory(categoryId.value)
          })
          /* console.log(categoryId.value) */
        }

        /* const {categories} = useProduct() */
        onMounted(async () => {
          await getCategories()
          orderList.value = categories.value.map(cat => cat.id)
        })



        watch(() => props.categoriesData, () => {
            categories.value = [...props.categoriesData]
        })

        const openChangeMenu = (element) => {
            emit('open-change-menu', element)
        }

        const getAttr = (id) => {
          categoryId.value = id
          getAttrCategory(categoryId.value)
        }

        const delAttr = (id) => {
          deleteAttrCategory(id)
          .then(() => {
            getAttrCategory(categoryId.value)
          })
        }

        const orderList = ref(null)
        const isLoading = ref(false)
        const dragEnd = (e) => {
          insert(e.newIndex, e.oldIndex)
          onReorderCategories(orderList)
        }
        const insert = (newIndex, oldIndex) => {
          const tmp = orderList.value[oldIndex]
          orderList.value.splice(oldIndex, 1)
          orderList.value.splice(newIndex, 0, tmp)
        }
        const onReorderCategories = async (orderList) => {
          isLoading.value = true
          await reorderCategories({order: orderList.value}).finally(() => {
            isLoading.value = false
            getCategories()
          })
        }

        const updateAttr = (attr) => {
          nameAttr.value = attr.name
          optional.value = !attr.optional
          changeId.value = attr.id
        }
        
        const changeAttr = () => {
          changeAttrCategory(changeId.value, {
            name: nameAttr.value,
            optional: !optional.value,
          })
          .then(() => {
            nameAttr.value = ""
            optional.value = false
            changeId.value = null
            getAttrCategory(categoryId.value)
          })
        }
        const changeId = ref(null)

        const reset = () => {
            nameAttr.value = ""
            optional.value = false
            changeId.value = null
        }
        return{
            categories,
            openChangeMenu,
            del,
            getAttr,
            attrCategoryes,
            optional,
            optionalActive,
            dragEnd,
            onReorderCategories,
            isLoading,
            nameAttr,
            addAttr,
            delAttr,
            changeAttr,
            updateAttr,
            changeId,
            reset,
            categoryId,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/product/categories-list.scss"></style>

<style lang="scss">
    .buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}
</style>