<template>
    <div class="points">
        <div class="points__header">
            <div class="points__header_title">Точки  продаж</div>
            <div class="points__header_button pointer" @click="openAddSalePointMenu">Добавить
                <img src="@/assets/add_box_white.svg" alt="">
            </div>
        </div>
        <div class="points__main" v-if="salePointList?.length">
          <template v-for="(salePoint, index) in salePointList" :key="index">
            <div class="points__main-item" v-if="salePoint.is_visible">
              <div class="points__main-item_name">{{ salePoint.name }}</div>
                 <div class="points__buttons">
                   <div class="points__buttons_change" @click="openUpdateSalePointMenu(salePoint)">Изменить</div>
                   <img @click="deleteSalePoint(salePoint)" style="background: #FFFFFF; padding: 2px;" class="pointer" src="@/assets/delete-red.svg" alt="">
                </div>
            </div>
          </template>
        </div>
    </div>
    <right-menu
        v-if="selectedSalePoint"
        :is-open="isOpenMenu"
        :submitBtnValue="rightMenu.submitBtnValue"
        @submit="submitHandler"
        @close-menu="closeRightMenu"
        @click.self="closeColorPicker">
      <template #header>
        {{ rightMenu.headerTitle }}
      </template>
      <template #content>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Название</div>
          <input v-model="selectedSalePoint.name" placeholder="Название" class="right-menu__content__item-input"/>
        </div>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Станция метро</div>
          <input v-model="selectedSalePoint.subname" placeholder="Станция метро" class="right-menu__content__item-input"/>
        </div>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Адрес</div>
          <input v-model="selectedSalePoint.address" placeholder="Адрес" class="right-menu__content__item-input"/>
        </div>
        <div class="right-menu__content__item" v-if="selectedSalePoint.color">
          <div class="right-menu__content__item-title">Цвет</div>
          <ColorPicker
              :key="colorPickerKey"
              ref="colorPicker"
              class="input-color-picker"
              v-show="isOpenColorPicker"
              theme="light"
              :color="selectedSalePoint.color"
              :colors-default="[
                  '#f7241b', '#009a56', '#005aa5', '#00a1e2',
                  '#765b30', '#ff893a', '#be2d8c', '#ffd41b',
                  '#acadaf', '#b0ce4a', '#87cdff', '#f771ae',
                  '#e9bbe2', '#4cc3bf',
                  ]"
              :sucker-hide="false"
              @changeColor="changeColor"
          />
          <div class="input-color w100" @click="updateColorPickerState">
            <input v-model="selectedSalePoint.color" disabled placeholder="Цвет" class="right-menu__content__item-input pointer"/>
            <div class="input-color-icon d-flex gap-8">
              <svg xmlns="http://www.w3.org/2000/svg" :fill="selectedSalePoint.color" width="18px" height="18px" viewBox="0 0 512 512">
                <path d="M512 255.1C512 256.9 511.1 257.8 511.1 258.7C511.6 295.2 478.4 319.1 441.9 319.1H344C317.5 319.1 296 341.5 296 368C296 371.4 296.4 374.7 297 377.9C299.2 388.1 303.5 397.1 307.9 407.8C313.9 421.6 320 435.3 320 449.8C320 481.7 298.4 510.5 266.6 511.8C263.1 511.9 259.5 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256V255.1zM96 255.1C78.33 255.1 64 270.3 64 287.1C64 305.7 78.33 319.1 96 319.1C113.7 319.1 128 305.7 128 287.1C128 270.3 113.7 255.1 96 255.1zM128 191.1C145.7 191.1 160 177.7 160 159.1C160 142.3 145.7 127.1 128 127.1C110.3 127.1 96 142.3 96 159.1C96 177.7 110.3 191.1 128 191.1zM256 63.1C238.3 63.1 224 78.33 224 95.1C224 113.7 238.3 127.1 256 127.1C273.7 127.1 288 113.7 288 95.1C288 78.33 273.7 63.1 256 63.1zM384 191.1C401.7 191.1 416 177.7 416 159.1C416 142.3 401.7 127.1 384 127.1C366.3 127.1 352 142.3 352 159.1C352 177.7 366.3 191.1 384 191.1z"/></svg>
            </div>
          </div>
        </div>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">График</div>
          <textarea v-model="selectedSalePoint.schedule" placeholder="График" class="right-menu__content__item-textarea"/>
        </div>
        <div class="right-menu__content__item" v-if="cityList?.length">
          <div class="right-menu__content__item-title">Город</div>
          <v-select v-model="selectedSalePoint.city" :options="cityList" :clearable="false" label="name"></v-select>
        </div>
        <div class="right-menu__content__item" v-if="cityList?.length">
          <div class="right-menu__content__item-title">Открыта</div>
          <base-switch small v-model="selectedSalePoint.is_open" />
        </div>
      </template>
    </right-menu>
</template>

<script>
import {useCities} from "@/composition/useCities.js"
import RightMenu from "@/components/RightMenu";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import {useSalePoints} from "@/composition/useSalePoint";
import { ColorPicker } from 'vue-color-kit'
import BaseSwitch from "../../../components/BaseSwitch";

const {getCities, cities } = useCities()
const {getSalePointList, salePointList, createSalePoint, updateSalePoint } = useSalePoints()
export default {
  components: {
    BaseSwitch,
    ColorPicker,
    RightMenu,
    vSelect,
  },
  data() {
    return {
      isOpenMenu: false,
      selectedSalePoint: {},
      salePointList: null,
      cityList: null,
      isOpenColorPicker: false,
      rightMenu: {
        type: "add",
        headerTitle: "Добавить точку продаж",
        submitBtnValue: "Добавить",
      },
      colorPickerKey: 'beforeReRender'
    }
  },
  async mounted() {
    await getCities()
    await getSalePointList()
    this.salePointList = [ ...salePointList.value ]
    this.cityList = [ ...cities.value ]
  },
  methods: {
    updateColorPickerState() {
      this.isOpenColorPicker = !this.isOpenColorPicker
    },
    closeColorPicker() {
      this.isOpenColorPicker = false
    },
    changeColor(color) {
      this.selectedSalePoint.color = color.hex
    },
    openAddSalePointMenu() {
      this.rightMenu = {
        type: "add",
        headerTitle: "Добавить точку продаж",
        submitBtnValue: "Добавить",
      }
      this.selectedSalePoint = {
        name: "",
        subname: "",
        color: "#ee5129",
        schedule: "24/7",
        city: {
          id: 1, name: "Москва"
        },
        address: "",
        is_warehouse: false,
        is_visible: true,
        is_open: true,
        lat: null,
        lon: null
      }
      this.isOpenMenu = true;
    },
    closeRightMenu() {
      this.isOpenMenu = false;
    },
    async submitHandler() {
      if (this.rightMenu.type === "add") {
        await this.createNewSalePoint()
      } else if (this.rightMenu.type === "edit") {
        await this.updateSalePoint()
      }
      this.isOpenMenu = false
    },
    async createNewSalePoint() {
      this.colorPickerKey = this.colorPickerKey  === 'beforeReRender' ? 'afterReRender': 'beforeReRender'
      const toCreate = { ...this.selectedSalePoint }
      toCreate.city = toCreate.city.id
      await createSalePoint(toCreate)
      await getSalePointList()
      this.salePointList = [ ...salePointList.value ]
    },
    async deleteSalePoint(salePoint) {
      const {id, ...payload} = { ...salePoint }
      payload.city = payload.city.id
      payload.is_visible = false
      await updateSalePoint(id, payload)
      await getSalePointList()
      this.salePointList = [ ...salePointList.value ]
    },
    openUpdateSalePointMenu(salePoint) {
      this.colorPickerKey = this.colorPickerKey  === 'beforeReRender' ? 'afterReRender': 'beforeReRender'
      this.selectedSalePoint = null
      this.selectedSalePoint = { ...salePoint }
      this.isOpenMenu = true;
      this.rightMenu = {
        type: "edit",
        headerTitle: "Изменить точку продаж",
        submitBtnValue: "Сохранить",
      }
    },
    async updateSalePoint() {
      const {id, ...toUpdate } = this.selectedSalePoint
      toUpdate.city = toUpdate.city.id
      await updateSalePoint(id, toUpdate)
      await getSalePointList()
      this.salePointList = [ ...salePointList.value ]
    }
  },
}
</script>

<style lang="scss">
@import "@/styles/profile/locations/points.scss";

</style>

