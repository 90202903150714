
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useStock() {

    const { api, params, Token, store_id } = useApi()
    
    const baners = ref()

    const getBaners = () => {
       return axios.get(api + `/cp/stores/${store_id.value}/banners/?type=all`, params)
       .then((r) => {
           baners.value = [ ...r.data.data ].map(banner => {
               delete banner.article_body
               return banner
           })
           return [ ...baners.value ]
       })
    }
    const createBaner = (dataBaners) => {
        return axios.post(api + `/cp/stores/${store_id.value}/banners/`, dataBaners, params)
    }

    const changeBanerImg = (id_baner, image) => {
        const formData = new FormData()
        formData.append('img',  image)
        const config = {
            headers: {
                "Authorization": Token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.put(api + `/cp/banners/${id_baner}/img/`, formData, config)
    }

    const deleteBaner = (id_baner) => {
        return axios.delete(api + `/cp/banners/${id_baner}/`, params)
    }

    const changeBaner = (id_baner, dataBaner) => {
        return axios.put(api + `/cp/banners/${id_baner}/`, dataBaner, params)
    }

    return {
        getBaners,
        createBaner,
        deleteBaner,
        changeBaner,
        changeBanerImg,
        baners,
    }
}