
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function usePrinciples() {

    const { api, params } = useApi()


    const principleList = ref()

    const getPrincipleList = () => {
        return axios.get(api + `/cp/principles/`, params)
            .then((r) => {
                principleList.value = r.data.data
            })
    }
    const createPrinciple = (payload) => {
        return axios.post(api + `/cp/principles/`, payload, params)
    }
    const updatePrincipleById = (principleId, payload) => {
        return axios.put(api + `/cp/principles/${principleId}/`, payload, params)
    }
    const deletePrincipleById = (principleId ) => {
        return axios.delete(api + `/cp/principles/${principleId}/`, params)
    }

    return {
        getPrincipleList,
        createPrinciple,
        updatePrincipleById,
        deletePrincipleById,
        principleList,
    }
}