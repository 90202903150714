<template>
<div class="subscriptions">
    <div class="subscriptions__header">
        <div class="subscriptions__list">
            <div class="subscriptions__item">
                <div class="subscriptions__item-info">
                    <div class="subscriptions__item_title">Standart</div>
                    <div class="subscriptions__item-capabilities">
                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Количество товаров:</div>
                            <div class="subscriptions__item-capabilities-item_value">100</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Количество магазинов:</div>
                            <div class="subscriptions__item-capabilities-item_value">1</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Акции:</div>
                            <div class="subscriptions__item-capabilities-item_value">3</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Смена интерфейса:</div>
                            <div class="subscriptions__item-capabilities-item_value">1</div>
                        </div>
                    </div>
                    <div class="subscriptions__item-sheet">
                        <div class="subscriptions__item-sheet-item" v-for="(item, index) in standart" :key="index">
                            <div class="subscriptions__item-sheet-item_title">{{item.name}}</div>
                            <img src="@/assets/checked.svg" class="subscriptions__item-sheet-item_img">
                        </div>
                    </div>
                </div>
                <div class="subscriptions__buttons">
                    <div class="subscriptions__buttons_price">18000 ₽/мес</div>
                    <div class="subscriptions__buttons_pay pointer">Купить</div>
                </div>
            </div>

            <div class="subscriptions__item">
                <div class="subscriptions__item-info">
                    <div class="subscriptions__item_title">Premium</div>
                    <div class="subscriptions__item-capabilities">
                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Количество товаров:</div>
                            <div class="subscriptions__item-capabilities-item_value">100</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Количество магазинов:</div>
                            <div class="subscriptions__item-capabilities-item_value">1</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Акции:</div>
                            <div class="subscriptions__item-capabilities-item_value">3</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Смена интерфейса:</div>
                            <div class="subscriptions__item-capabilities-item_value">1</div>
                        </div>
                    </div>
                    <div class="subscriptions__item-sheet">
                        <div class="subscriptions__item-sheet-item" v-for="(item, index) in premium" :key="index">
                            <div class="subscriptions__item-sheet-item_title">{{item.name}}</div>
                            <img src="@/assets/checked.svg" class="subscriptions__item-sheet-item_img">
                        </div>
                    </div>
                </div>
                <div class="subscriptions__buttons">
                    <div class="subscriptions__buttons_price">18000 ₽/мес</div>
                    <div class="subscriptions__buttons_pay pointer">Купить</div>
                </div>
            </div>

            <div class="subscriptions__item">
                <div class="subscriptions__item-info">
                    <div class="subscriptions__item_title">Ultra</div>
                    <div class="subscriptions__item-capabilities">
                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Количество товаров:</div>
                            <div class="subscriptions__item-capabilities-item_value">100</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Количество магазинов:</div>
                            <div class="subscriptions__item-capabilities-item_value">1</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Акции:</div>
                            <div class="subscriptions__item-capabilities-item_value">3</div>
                        </div>

                        <div class="subscriptions__item-capabilities-item">
                            <div class="subscriptions__item-capabilities-item_title">Смена интерфейса:</div>
                            <div class="subscriptions__item-capabilities-item_value">1</div>
                        </div>
                    </div>
                    <div class="subscriptions__item-sheet">
                        <div class="subscriptions__item-sheet-item" v-for="(item, index) in ultra" :key="index">
                            <div class="subscriptions__item-sheet-item_title">{{item.name}}</div>
                            <img src="@/assets/checked.svg" class="subscriptions__item-sheet-item_img">
                        </div>
                    </div>
                </div>
                <div class="subscriptions__buttons">
                    <div class="subscriptions__buttons_price">18000 ₽/мес</div>
                    <div class="subscriptions__buttons_pay pointer">Купить</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ref,
    onMounted
} from "vue"
import {
    useSubscriptions
} from "@/composition/useSubscriptions.js"
export default {
    data() {
        return {
            standart: [
                {name: "Уведомления пользователей"},
                {name: "Выгрузка отчета по продажам"},
                {name: "История покупок"},
                {name: "Online-оплата"},
            ],
            premium: [
                {name: "Уведомления пользователей"},
                {name: "Выгрузка отчета по продажам"},
                {name: "История покупок"},
                {name: "Online-оплата"},

                {name: "Отзывы"},
                {name: "Рейтинг товаров"},
                {name: "Интеграция с EPR системами"},
                {name: "Статистика продаж"},
            ],

            ultra: [
                {name: "Уведомления пользователей"},
                {name: "Выгрузка отчета по продажам"},
                {name: "История покупок"},
                {name: "Online-оплата"},

                {name: "Отзывы"},
                {name: "Рейтинг товаров"},
                {name: "Интеграция с EPR системами"},
                {name: "Статистика продаж"},

                {name: "Смена интерфейса карточки товара"},
                {name: "Смена цветов и стилей приложения"},
                {name: "Кэшбек"},
            ]
        }
    },
    setup() {
        const {
            getSubscriptions,
            subscriptions
        } = useSubscriptions()
        onMounted(() => {
            /* getGroups() */
        })
        return {
            subscriptions,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/subscriptions/subscriptions.scss"></style>
