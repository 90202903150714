
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useCities() {

    const { api, params, store_id } = useApi()
    const cities = ref()
    const getCities = () => {
        return axios.get(api + `/cp/stores/${store_id.value}/cities/`, params)
        .then((r) => {
            cities.value = r.data.data
        })
    }
    const postCities = (body) => {
        return axios.post(api + `/cp/stores/${store_id.value}/cities/`,body, params)
    }

    const putCitie = (body, city_id) => {
        return axios.put(api + `/cp/stores/cities/${city_id}/`, body, params)
    }
    const delCitie = (city_id) => {
        return axios.delete(api + `/cp/stores/cities/${city_id}/`, params)
    }
    return {
        getCities,
        postCities,
        putCitie,
        delCitie,

        cities
    }
}