
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useSalePoints() {

    const { api, params, store_id } = useApi()
    const salePointList = ref()
    const getSalePointList = () => {
        return axios.get(api + `/stores/${store_id.value}/sale-points/`)
            .then((r) => {
                salePointList.value = r.data.data
            })
    }
    const createSalePoint = (body) => {
        return axios.post(api + `/cp/stores/${store_id.value}/sale-points/`,body, params)
    }

    const updateSalePoint = (salePointId, body) => {
        return axios.put(api + `/cp/stores/sale-points/${salePointId}/`, body, params)
    }
    return {
        getSalePointList,
        createSalePoint,
        updateSalePoint,

        salePointList,
    }
}