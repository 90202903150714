
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function usePages() {

    const { api, params, store_id } = useApi()
    const pages = ref()
    const page = ref()
    const getPages = () => {
        return axios.get(api + `/cp/stores/${store_id.value}/pages/`, params)
        .then((r) => {
            pages.value = r.data.data
        })
    }
    const getPage = (page_id) => {
        return axios.get(api + `/cp/stores/pages/${page_id}/`, params)
        .then((r) => {
            page.value = r.data.data
        })
    }
    const putPage = (body, page_id) => {
        return axios.put(api + `/cp/stores/pages/${page_id}/`, body, params)
    }
    return {
        pages,
        page,

        getPages,
        getPage,
        putPage,
    }
}