
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useManagers() {

    const { api, params, Token } = useApi()


    const managerList = ref()

    const getManagerList = () => {
        return axios.get(api + `/cp/employees/`, params)
            .then((r) => {
                managerList.value = r.data.data
            })
    }
    const createManager = (payload) => {
        return axios.post(api + `/cp/employees/`, payload, params)
    }
    const updateManagerById = (managerId, payload) => {
        return axios.put(api + `/cp/employees/${managerId}/`, payload, params)
    }
    const createManagerImgById = (managerId, image) => {
        const formData = new FormData()
        formData.append('img',  image)
        const config = {
            headers: {
                "Authorization": Token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.put(api + `/cp/employees/${managerId}/images/`, formData, config)
    }
    const deleteManagerById = (managerId ) => {
        return axios.delete(api + `/cp/employees/${managerId}/`, params)
    }

    return {
        getManagerList,
        createManager,
        updateManagerById,
        deleteManagerById,
        createManagerImgById,
        managerList,
    }
}