<template>
  <div class="principles">
    <div class="principles__header">
      <div class="principles__header_title">Принципы</div>
      <!-- 
      <div class="principles__header_button pointer" @click="openAddMenu">Добавить
        <img src="@/assets/add_box_white.svg" alt="">
      </div>
      -->
    </div>
      <template v-if="principleList?.length">
        <ul class="principles__list d-flex gap-16 flex-wrap">
          <li v-for="priciple in principleList" :key="priciple.id" class="principles__list__item">
            <div class="principles__list__item-title">
              {{ priciple.name }}
            </div>
            <div class="principles__list__item__actions">
              <div @click="openUpdateMenu(priciple)" class="principles__list__item__actions-update">
                Изменить
              </div>
            </div>
          </li>
        </ul>
      </template>

    <right-menu
        :is-open="isOpenMenu"
        :submitBtnValue="rightMenu.submitBtnValue"
        @submit="submitHandler"
        @close-menu="closeRightMenu">
      <template #header>
        {{ rightMenu.headerTitle }}
      </template>
      <template #content v-if="Object.keys(selectedPrinciple).length">
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Наименование</div>
          <input v-model="selectedPrinciple.name" placeholder="Наименование" class="right-menu__content__item-input"/>
        </div>

        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Содержание</div>
          <textarea v-model="selectedPrinciple.body" class="right-menu__content__item-textarea"></textarea>
        </div>
      </template>
    </right-menu>
  </div>
</template>

<script>
import RightMenu from "@/components/RightMenu";
import { usePrinciples } from '@/composition/usePrinciples.js'

const { principleList, getPrincipleList, createPrinciple, updatePrincipleById, deletePrincipleById } = usePrinciples()

export default {
  name: 'PrincipleView',
  components:{
    RightMenu,
  },

  data() {
    return {
      isOpenMenu: false,
      selectedPrinciple: {},
      principleList: [],
      rightMenu: {
        type: "add",
        headerTitle: "Добавить принцип",
        submitBtnValue: "Добавить",
      },
    }
  },
  async created() {
    await this.updatePrincipleList()
  },
  methods: {
    async updatePrincipleList() {
      await getPrincipleList()
      this.principleList = principleList
    },
    async submitHandler() {
      if (this.rightMenu.type === "add") {
        await this.createPrinciple()
      } else if (this.rightMenu.type === "edit") {
        await this.updatePrinciple()
      }
    },
    closeRightMenu() {
      this.isOpenMenu = false
    },
    async createPrinciple() {
      const toCreate = { ...this.selectedPrinciple }

      await createPrinciple(id, { ...toCreate })

      this.selectedPrinciple = {}
      this.closeRightMenu()
    },
    async updatePrinciple() {
      const { id, ...toUpdate } = { ...this.selectedPrinciple }
    
      await updatePrincipleById(id, { ...toUpdate })

      await this.updatePrincipleList()
      this.selectedPrinciple = {}
      this.closeRightMenu()
    },
    openAddMenu() {
      this.selectedPrinciple = {
        name: null,
        body: null,
      }
      this.rightMenu = {
        type: "add",
        headerTitle: "Добавить принцип",
        submitBtnValue: "Добавить",
      }
      this.isOpenMenu = true
    },
    async openUpdateMenu(toUpdate) {
      this.rightMenu = {
        type: "edit",
        headerTitle: "Изменить принцип",
        submitBtnValue: "Сохранить",
      }

      this.selectedPrinciple = { ...toUpdate }

      this.isOpenMenu = true
    },
  },
}
</script>

<style lang="scss">
  @import "@/styles/profile/informations/principles.scss";
</style>