<template>
<div class="login">
    <div class="login__wrapper">
        <div class="login__aut">
            <form class="login__form">
                 <img src="@/assets/franklins_logo.svg" alt="">
                <div class="login__form_title">Вход</div>
                <div class="input-container ic1">
                    <input id="login" class="input" type="text" placeholder=" " v-model="form.target"/>
                    <div class="cut"></div>
                    <label for="firstname" class="placeholder">Email</label>
                </div>

                <div class="input-container ic1">
                    <input id="password" class="input" type="password" autocomplete="off" placeholder=" " v-model="form.password"/>
                    <div class="cut"></div>
                    <label for="firstname" class="placeholder">Пароль</label>
                </div>

                <div class="login__forgot">
                    <a class="login__forgot_name pointer" @click="routerPush('Recover')">Забыли пароль ?</a>
                </div>
                <div class="login__buttons">
                    <div class="login__buttons_login" @click="login">Войти</div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import {
    ref, onMounted
} from 'vue';
import { useRouter } from "vue-router"
import {
    useLogin
} from '@/composition/useLogin.js';

export default ({
    setup() {
        const router = useRouter()
        const routerPush = (name) => {
            router.push({
                name: name
            })
        }
        const {
            loginUser
        } = useLogin()

        const form = ref({
            target: "",
            password: "",
        })

        const login = () => {
            loginUser(form.value)
                .then(() => {
                    routerPush('Profile')
                })
                .catch(() => {
                    alert("Bad")
                })
        }

        onMounted(() => {
            document.addEventListener('keydown', function(e) {
                if (e.code == 'Enter') {
                    login()
                }
            });
        })
        return {
            routerPush,

            form,
            login,
        }
    }
});
</script>

<style lang="scss" src="@/styles/autorization/login.scss"></style>
