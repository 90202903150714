<template>
<div class="right-menu-categories-list">
    <div class="right-menu-categories-list__header">
        <div class="right-menu-categories-list__header_title">Изменить категорию</div>
    </div>
    <div class="right-menu-categories-list__controls">
        <div class="right-menu-categories-list__info">
        <div class="right-menu-categories-list__input">
          <div class="right-menu-categories-list__input_title" style="margin-bottom: 10px">Выберите изображение</div>
        </div>
        <div class="d-flex w100 justify-center">
          <div  style="width: 120px; height: 120px;">
              <v-image-input v-model="categoryDataToUse.thumbnail">
                <template #empty-layout>
                  <div class="image-layout">
                    Нажмите или перетащите изображение
                  </div>
                </template>
              </v-image-input>
            </div>
          </div>
            <div class="right-menu-categories-list__input">
                <div class="right-menu-categories-list__input_title">Имя</div>
                <input v-model="categoryDataToUse.name" class="right-menu-categories-list__input_value">
            </div>
        </div>
        <div class="right-menu-categories-list__buttons d-flex gap-16">
          <input type="button" class="btn btn-outline" value="Отмена" @click="closeChangeMenu">
          <input type="button" class="btn btn-main" value="Изменить" @click="updateCategory">
        </div>
    </div>
</div>
</template>

<script>
import {useProduct} from "@/composition/useProduct.js"
import VImageInput from "vue3-img-input";

const { changeCategory, changeCategoryPicture } = useProduct()
export default {
  components:{
    VImageInput,
  },
  props: {
      categoryData: {
        type: Object,
        required: true
      },
  },
  data () {
    return {
      categoryDataToUse: null,
    }
  },
  methods: {
    async updateCategory(){
      const {id, thumbnail, subcategories, ...toUpdate} = {
        ...this.categoryDataToUse
      }
      if (thumbnail !== this.categoryData.thumbnail) {
        await changeCategoryPicture(id, thumbnail)
      }

      await changeCategory(id, { ...toUpdate })

      this.$emit('update-category')
      this.$emit('close-change-menu')
    },
   closeChangeMenu(){
        this.$emit('close-change-menu')
    },
  },
  watch: {
    categoryData: {
      immediate: true,
      handler() {
        this.categoryDataToUse = { ...this.categoryData }
      }
    },
  },
  beforeMount() {

  },
}
</script>

<style lang="scss" src="@/styles/profile/product/RightMenuCategories.scss"></style>
