<template>
    <div class="cities">
        <div class="cities__header">
            <div class="cities__header_title">Города</div>
            <div class="cities__header_button pointer" @click="addedetNew = !addedetNew">Добавить
                <img src="@/assets/add_box_white.svg" alt="">
            </div>
        </div>
        <div class="cities__main">
            <div class="cities__main-item" v-for="(citie, index) in cities" :key="index">
                <input class="cities__main-item_name" v-model="citie.name">
                 <div class="cities__buttons">
                    <div class="cities__buttons_change" @click="citieChange(citie)">Изменить</div>
                    <img @click="citieDelete(citie)" style="background: #FFFFFF; border-top: 1px solid #F2623F; padding: 2.3px 2.8px; border-radius: 0px" class="pointer" src="@/assets/delete-red.svg" alt="">
                </div>
            </div>
            <div class="cities__main-item" v-if="addedetNew">
                <input class="cities__main-item_name" v-model="newCitie.name">
                 <div class="cities__buttons">
                    <div class="cities__buttons_change" @click="addNewCitie">Добавить</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, watch} from "vue"

import {useCities} from "@/composition/useCities.js"
export default {
    components: {
    },
    setup(props, {emit}) {
        const {getCities, cities, postCities, putCitie, delCitie} = useCities()

        onMounted(() => {
            getCities()
        })

        const newCitie = ref({
            name: ""
        })

        const addedetNew = ref(false)

        const addNewCitie = () => {
            postCities(newCitie.value)
            .then(() => {
                 getCities()
                 addedetNew.value = false
                 newCitie.value.name = ""
            })
        }

        const citieChange = (citie) => {
            putCitie({
                name: citie.name,
            }, citie.id)
        }

        const citieDelete = (citie) => {
            delCitie(citie.id)
            .then(() => {
                getCities()
            })
        }
        
        return{
            cities,
            addedetNew,
            newCitie,
            addNewCitie,
            citieChange,
            citieDelete,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/locations/cities.scss"></style>

