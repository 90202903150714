<template>
  <draggable
      :list="productList"
      item-key="id"
      class="products__list"
      ghost-class="ghost"
      @end="dragEnd"
  >
    <template #item="{ element }">
      <div class="products__item">
        <div class="products__item_img">
          <img style="width: 114px; height: 80px;" :src="element.thumbnail" alt="">
        </div>
        <div class="products__item_name">{{element.name}}</div>
        <div class="products__item_description">{{element.description}}</div>
        <div class="products__item_price" v-if="element.old_price">{{element.price}}₽ / {{element.old_price}}₽</div>
        <div class="products__item_price" v-else>{{element.price}}₽ / 0₽</div>
        <div class="products__item_categories">{{element.categories[0].name}}</div>
        <div class="products__item_is-deleted">
          <img class="products__item_change" style="" src="@/assets/settings.svg" @click="$emit('openUpdateModal', element.id)" alt="">
          <img class="products__item_del" style="" src="@/assets/delete.svg" alt="" @click="$emit('deleteProduct', (element.id))">
        </div>
      </div>
    </template>
  </draggable>
  <base-loader :is-open="isLoading" />
</template>

<script>
import draggable from "vuedraggable";
import {useProduct} from "@/composition/useProduct";
import BaseLoader from "@/components/BaseLoader";
const {reorderProducts} = useProduct()

export default {
  name: "ProductList",
  components: {BaseLoader, draggable},
  props: {
    productList: {
      type: Array,
    }
  },
  data() {
    return {
      orderList: null,
      isLoading: false,
    }
  },
  emits: ['openUpdateModal', 'deleteProduct', 'updateProductList'],
  mounted() {

  },
  methods: {
    dragEnd(e) {
      this.insert(e.newIndex, e.oldIndex)
      this.onReorderProducts(this.orderList)
    },
    insert(newIndex, oldIndex) {
      const tmp = this.orderList[oldIndex]
      this.orderList.splice(oldIndex, 1)
      this.orderList.splice(newIndex, 0, tmp)
    },
    async onReorderProducts(newOrderList) {
      this.isLoading = true
      await reorderProducts({order: newOrderList}).finally(() => {
        this.isLoading = false
        this.$emit('updateProductList')
      })
    }
  },
  watch: {
    productList: {
      immediate: true,
      handler() {
        if (this.productList?.length) {
          this.orderList = this.productList.map(item => item.id)
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/profile/product/products.scss";
</style>