<template>
  <div @click="closeModal" v-if="isOpen" class="modal">
    <div @click.stop class="modal__content d-flex flex-column gap-16">
      <div class="modal__content-header color-main flex-center bold no-select">
        <slot name="header" />
      </div>
      <div class="modal__content-inputs d-flex gap-16 flex-column">
        <slot name="inputs" />
      </div>
      <div class="modal__content-buttons d-flex space-between gap-16">
        <slot name="buttons">
          <input
              @click="closeModal"
              class="btn btn-outline"
              type="button"
              value="Отмена"
          />
          <input
              v-if="isDefaultButton"
              :value="defaultButtonValue"
              @click="defaultButtonAction"
              class="btn btn-main"
              type="button"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  emits: ['close-modal'],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    defaultButtonAction: {
      type: Function,
      required: false,
    },
    isDefaultButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultButtonValue: {
      type: String,
      required: false,
      default: 'Загрузить',
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
  },
}
</script>

<style lang="scss">
@import "@/styles/app/colors.scss";

.modal {
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba($color-orange-light, 0.1);

  &__content {
    position: relative;
    justify-content: space-between;
    padding: 25px 45px;
    min-width: 250px;
    border-radius: 20px;
    background: white;

    .btn-close {
      background: $color-orange-light;
      color: white;
      width: 24px;
      font-weight: 900;
      padding: 5px;
      position: absolute;
      border-radius: 5px;
      right: 12px;
      top: 12px;

      &:hover {
        box-shadow: 2px 2px 4px #15151555 !important;

        &:hover {
          box-shadow: 2px 2px 8px rgba($color-orange-light, 0.6) !important;
        }
      }
    }

    &-header {
      font-size: 22px;
    }
  }
}
</style>
