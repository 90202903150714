<template>
  <div class="baners-list">
      <div class="baners-list__titles">
          <div class="baners-list__titles_img">Изображение</div>
          <div class="baners-list__titles_name">Наименование</div>
          <div class="baners-list__titles_link">Ссылка</div>
          <div class="baners-list__titles_article">Статья</div>
          <div class="baners-list__titles_category">Категория</div>
          <div class="baners-list__titles_product">Товар</div>
          <div class="baners-list__titles_startdate">Дата начала / Дата окончания</div>
          <div class="baners-list__titles_controls">Управление</div>
      </div>
  </div>
  <div class="baners-list-items">
      <div class="baners-list-items__item" v-for="(banner, index) in bannerList" :key="index">

          <!-- <div class="baners-list-items__item_img" v-if="!baner.img">img</div> -->
          <img :src="banner.img" class="baners-list-items__item_img">

          <div class="baners-list-items__item_name">{{banner.name}}</div>

          <div class="baners-list-items__item_link">{{banner.link}}</div>

          <div class="baners-list-items__item_article">{{ !banner.article?.title ? "Нет статьи" : banner.article.title }}</div>

          <div class="baners-list-items__item_category">{{ !banner.category?.name ? "Нет категории" : banner.category.name }}</div>

          <div class="baners-list-items__item_product">{{ !banner.product?.name ? "Нет товара" : banner.product.name }}</div>

          <div class="baners-list-items__item_startdate">{{new Date(banner.start_at * 1000).toLocaleDateString()}} / {{new Date(banner.end_at * 1000).toLocaleDateString()}}</div>

          <div class="baners-list-items__item_controls">
            <img src="@/assets/visibility.svg" class="baners-list-items__item_controls-show" style="display: none">
            <img src="@/assets/visibility_off.svg" class="baners-list-items__item_controls-unshow" style="display: none">
            <img src="@/assets/settings.svg" class="baners-list-items__item_controls-changed" @click="openChaneBannerMenu(banner)">
            <img src="@/assets/delete.svg" class="baners-list-items__item_controls-delete" @click="deleteBanner(banner.id)">
          </div>
      </div>
  </div>
</template>

<script>
import {ref, onMounted, onUpdated, watch} from "vue"
import {useStock} from '@/composition/useStock.js'
import {useApi} from "@/composition/useApi.js"
import {useProduct} from "@/composition/useProduct";

export default {
  emits: ['open-change-banner-menu', 'delete-banner'],
  props: {
    categoryList: {
      type: Array,
      required: true,
    },
    productList: {
      type: Array,
      required: true,
    },
    articleList: {
      type: Array,
      required: true,
    },
    bannerList: {
      type: Array,
      required: true,
    },
  },
  data()  {
    return {
      bannerListToUse: null
    }
  },
  watch: {
    bannerList: {
      immediate: true,
      handler() {
        this.bannerListToUse = [ ...this.bannerList]
        this.bannerListToUse.map((banner) => {
          const category = { ...this.categoryList.find(c => c.id === banner.category) }
          banner.category = {id: category.id, name: category.name}
          const product = {  ...this.productList.find(c => c.id === banner.product) }
          banner.product = {id: product.id, name: product.name}
          banner.article = {  ...this.articleList.find(c => c.id === banner.article) }
          return banner
        })
      }
    }
  },
  methods: {
    openChaneBannerMenu(element) {
      this.$emit('open-change-banner-menu', element)
    }
  },
  setup(props, {emit}) {
    const {deleteBaner, changeBaner, createBaner} = useStock()

    const deleteBanner = async (id) => {
      await deleteBaner(id)
      emit("delete-banner")
    }

    return{
      createBaner,
      deleteBanner,
      changeBaner,
    }
  }
}
</script>

<style lang="scss" src="@/styles/profile/stock/baners-list.scss"></style>