<template>
<div class="menu-profile">
    <div class="menu-profile__wrapper">
        <!-- <div class="menu-profile__stores-list">
            <div class="menu-profile__stores-item pointer" @click="openStoresList">
                <div class="menu-profile__stores-item_name">{{activeStore.name}}</div>
                <img src="@/assets/arrow.svg" class="menu-profile__stores-item_arrow" :class="{'reflect' : dropdown}">
            </div>
            <div v-if="dropdown" class="menu-profile__stores-list-drop">
                <div class="menu-profile__stores-item-drop pointer" @click="selectActiveStore(store.store.id, store.store.name)" v-for="(store, index) in stores" :key="index">
                    <div class="menu-profile__stores-item-drop_name" v-if="store.store">{{store.store.name}}</div>
                </div>
            </div>
        </div> -->
        <div class="menu-profile__list">
            <div class="menu-profile__item" v-for="(navigation, index) in navigations" :key="index">
                <div class="menu-profile__item-parent pointer" :class="{'menu-profile__item-parent-active': navigation.active}" @click="openNavigation(index)">
                    <div class="menu-profile__item-parent_name" :class="{'menu-profile__item-parent-active_name': navigation.active}">
                        {{navigation.title}}
                    </div>
                    <img src="@/assets/arrow.svg" alt="arrow" class="menu-profile__item-parent_arrow" :class="{'reflect': navigation.active}">
                </div>
                
                    <template v-if="navigation.active">
                        <template v-for="(link, jindex) in navigation.links" :key="jindex">
                            <router-link :to='link.link' class="menu-profile__item-child">
                                <div class="menu-profile__item-child_name">
                                    {{link.title}}
                                </div>
                            </router-link>
                        </template>
                    </template>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {ref, onMounted} from "vue"
/* import {useStore} from "@/composition/useStore.js" */
import {useApi} from "@/composition/useApi.js"
export default {
    data() {
        return {
            show: false,
            active: false,
            navigations: [
                /* {
                    title: "Подписка",
                    active: false,
                    links: [{
                        title: "Приобретение",
                        link: "/profile/subscriptions/",
                    },]
                },  */
                {
                    title: "Акции",
                    active: false,
                    links: [{
                        title: "Банеры",
                        link: "/profile/stock/baners/",
                    },]
                }, 
                {
                    title: "Товары",
                    active: false,
                    links: [{
                        title: "Категории",
                        link: "/profile/product/categories/",
                    },
                    {
                        title: "Товары",
                        link: "/profile/product/products/",
                    },]
                }, 
                {
                    title: "Информация",
                    active: false,
                    links: [
                        {
                          title: "Акции",
                          link: "/profile/informations/acts/",
                        },
                        {
                          title: "Партнеры",
                          link: "/profile/informations/partners/",
                        },
                        {
                            title: "Руководители",
                            link: "/profile/informations/managers/",
                        },
                        {
                            title: "Принципы",
                            link: "/profile/informations/principles/",
                        },
                        {
                            title: "Страницы",
                            link: "/profile/informations/pages/",
                        },
                        {
                            title: "Контакты",
                            link: "/profile/informations/contacts/",
                        },
                    ]
                }, 
                {
                    title: "Локация",
                    active: false,
                    links: [{
                        title: "Города",
                        link: "/profile/locations/cities/",
                    },
                    {
                        title: "Точки продаж",
                        link: "/profile/locations/points/",
                    },]
                }, 
            ],
            dropdown: false,
        }
    },

    methods: {
        openNavigation(index) {
            this.navigations[index].active = !this.navigations[index].active
        },
        openStoresList(){
            this.dropdown = !this.dropdown
        }
    },

    mounted() {
        this.navigations.forEach(el => {
            el.links.forEach(link => {
                if (link.link == this.$route.fullPath) {
                    /* this.active = true */
                    el.active = true
                } else {
                    /* this.active = false */
                    el.active = false
                }
            })
        });
    },

    setup() {
        /* const {getStores, stores} = useStore() */
        const {store_id} = useApi()
        onMounted(() => {
         /*  getStores()
          .then(() => {
              if(!localStorage.getItem("store")){
                  localStorage.setItem("store",JSON.stringify({store_id: stores.value[0].store.id, store_name: stores.value[0].store.name}))
                  activeStore.value.id = stores.value[0].store.id
                  activeStore.value.name = stores.value[0].store.name
              } else {
                  let store = JSON.parse(localStorage.getItem('store'))
                  activeStore.value.id = store.store_id
                  activeStore.value.name = store.store_name
              }
          }) */
        })

        const activeStore = ref({
            id: 0,
            name: "select",
        })

        const selectActiveStore = (id, name) => {
            activeStore.value.id = id
            activeStore.value.name = name
            localStorage.setItem("store" ,JSON.stringify({store_id: id, store_name: name}))
            store_id.value = id
        }
        return{
            /* stores, */
            store_id,

            activeStore,
            selectActiveStore,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/menuProfile.scss"></style>
