<template>
    <div class="header-profile">
        <div class="header-profile__wrapper">
            <div class="header-profile__logotype">
                <img src="@/assets/menu_white.svg" class="header-profile__logotype_menu-btn pointer" @click="updateSidebarState">
                <!-- <img src="@/assets/logo.svg" class="header-profile__logotype_logo"> -->
                <div class="header-profile__logotype_menu-name" style="font-weight: 900">FranklinsBurger</div>
            </div>
            <div class="header-profile__admin pointer" @click="updateModalWindowState">
                <div class="header-profile__admin_name">{{admin.name}}</div>
                <img v-if="!admin.avatar" src="@/assets/user.png" class="header-profile__admin_img">
                <img v-else :src="admin.avatar" class="header-profile__admin_img">
                <div class="header-profile__admin_logout" @click="logoutUser">
                    <img src="@/assets/logout.svg" alt="">
                </div>
            </div>
        </div>
    </div>
  <base-modal
      :is-open="isOpenModalWindow"
      :default-button-value="'Сохранить'"
      :default-button-action="updateProfile"
      @close-modal="updateModalWindowState"
  >
    <template #header>{{ !isEnterOtp ? "Изменить профиль" : "Введите код подтверждения" }}</template>
    <template v-if="!isEnterOtp" #inputs>
      <div class="d-flex flex-column gap-16">
        <div class="input-container ic1">
          <input id="firstname" class="input" type="text" autocomplete="off" placeholder=" " v-model.trim="profileToUpdate.name"/>
          <div class="cut"></div>
          <label for="firstname" class="placeholder">Имя</label>
        </div>
        <div class="input-container ic2">
          <input id="firstname" class="input" type="text" disabled autocomplete="off" placeholder=" " :value="admin.email"/>
          <div class="cut"></div>
          <label for="firstname" class="placeholder">Текущий email</label>
        </div>
        <div class="input-container ic2">
          <input id="firstname" class="input" type="text" autocomplete="off" placeholder=" " v-model.trim="profileToUpdate.email"/>
          <div class="cut"></div>
          <label for="firstname" class="placeholder">Новый email</label>
        </div>
      </div>
    </template>
    <template v-if="isEnterOtp" #inputs>
      <div class="d-flex justify-center">
        <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            class="gap-16"
            separator=""
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            :conditionalClass="['one', 'two', 'three', 'four']"
            @on-complete="onCompleteOtp"
        />
      </div>
    </template>
    <template v-if="isEnterOtp" #buttons>
      <label @click="isEnterOtp = !isEnterOtp" for="back-btn" class="d-flex align-center btn btn-main gap-8">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="18px" height="18px" viewBox="0 0 256 490"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>
        Назад
      </label>
      <label @click="onCompleteOtp" for="back-btn" class="d-flex align-center btn btn-main gap-8">
        Отправить
        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" viewBox="0 0 24 22">
          <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
        </svg>
      </label>
    </template>
  </base-modal>
</template>

<script>
import {ref, onMounted, computed} from "vue"
import {useAdmin} from "@/composition/useAdmin.js"
import BaseModal from "@/components/BaseModal";
import VOtpInput from 'vue3-otp-input';
export default {
  components: {BaseModal, VOtpInput},
  methods: {
        logoutUser(){
            localStorage.removeItem("Token")
            this.$router.push({name: "Login"})
        },
    },
    setup(props, {emit}) {
        const {getProfile, putProfileEmail, putProfileName, sendEmailCode} = useAdmin()

        const admin = ref({})
        const profileToUpdate = ref({})

        onMounted(() => {
          getProfile()
          .then((r) => {
              admin.value = r.data.data
              profileToUpdate.value = { ...admin.value }
              profileToUpdate.value.email = ""
          })
        })
        const profileSettings = ref(false)

        const isOpenModalWindow = ref(false)
        const updateModalWindowState = () => {
          isOpenModalWindow.value = !isOpenModalWindow.value
        }

        const isEnterOtp = ref(false)
        const onCompleteOtp = async (otpNumber) => {
          await putProfileEmail({email: profileToUpdate.value.email, code: otpNumber})
          isOpenModalWindow.value = false
          isEnterOtp.value = false
          otpNumber.value =  null
        }

        const isValidOrEmptyEmail = computed(() => {
          return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(profileToUpdate.value.email) || !profileToUpdate.value.email
        })

        const updateProfile = async () => {
          let closeModalWindow = true
          if (profileToUpdate.value.name !== admin.value.name) {
            await putProfileName({name: profileToUpdate.value.name})
          }

          if (profileToUpdate.value.email && isValidOrEmptyEmail.value) {
            await sendEmailCode('email', {target: profileToUpdate.value.email})
            isEnterOtp.value = true
            closeModalWindow  = false
          }

          if (closeModalWindow) {
            isOpenModalWindow.value = false
          }

        }

        const updateSidebarState = () => {
          emit("update:sidebarState")
        }

        return{
          admin,
          profileSettings,

          isOpenModalWindow,
          updateModalWindowState,
          profileToUpdate,
          updateProfile,
          onCompleteOtp,
          isEnterOtp,
          isValidOrEmptyEmail,
          updateSidebarState,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/headerProfile.scss">
</style>