<template>
    <div class="products">
        <div class="products__header">
            <div class="products__header_title">Товары</div>
            <div class="products__header_button pointer" @click="openModalAddMenu">Добавить
                <img src="@/assets/add_box_white.svg" alt="">
            </div>
        </div>
        <div class="products__titles">
            <div class="products__titles_img">Изображение</div>
            <div class="products__titles_name">Имя</div>
            <div class="products__titles_description">Описание</div>
            <div class="products__titles_price">Цена/Старая цена</div>
            <div class="products__titles_categories">Категория</div>
            <div class="products__titles_deleted">Управление</div>
        </div>
        <product-list :product-list="productList" @updateProductList="updateProductList" @openUpdateModal="openModalUpdateProduct" @deleteProduct="deleteProduct" />
    </div>
    <right-menu
        :is-open="rightMenu.isOpen"
        :submitBtnValue="rightMenu.submitBtnValue"
        @submit="submitHandler"
        :show-message="showMessage"
        @close-menu="closeRightMenu">
      <template #header>
        {{ rightMenu.headerTitle }}
      </template>
      <template #content>
        <div v-if="categoryList?.length" class="right-menu-products-list__input mb-16">
          <div class="right-menu__content__item-title">Категория</div>
          <v-select
            :disabled="rightMenu.type==='update'"
            :clearable="false"
            @option:selected="updateCategory"
            v-model="selectedProduct.category"
            :options="categoryList"
            label="name">
            </v-select>
        </div>
        <template v-if="selectedProduct.category">
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title">Изображение</div>
          <div class="w100 d-flex justify-center">
            <div class="image-wrapper" style="width: 120px; height: 120px;">
                <v-image-input v-model="selectedProduct.thumbnail">
                    <template #empty-layout>
                    <div class="image-layout">
                        Нажмите или перетащите изображение
                    </div>
                    </template>
                </v-image-input>
            </div>
          </div>
        </div>
        <div class="right-menu__content__item">
          <div class="right-menu__content__item-title required">Наименование</div>
          <input v-model="selectedProduct.name" placeholder="Наименование" class="right-menu__content__item-input" :class="{warning: !selectedProduct.name}"/>
        </div>
        <div class="right-menu-products-list__input">
            <div class="right-menu__content__item-title">Описание</div>
            <textarea v-model="selectedProduct.description" class="right-menu-products-list__input_textarea"></textarea>
        </div>
          <div class="right-menu-products-list__input">
            <div class="right-menu__content__item-title">Метка</div>
              <v-select :clearable="false" v-model="selectedProduct.tag" :options="markList" label="name"></v-select>
          </div>
          <div class="right-menu-products-list__input">
            <div class="right-menu__content__item-title">Вариации</div>
            <div v-if="variationList.length" class="variation-list">
              <div class="variation-list-item" v-for="(variation, index) in variationList" :key="index" >
                <div class="variation__content">
                  <div class="variation__content_title">{{ variation.name }}</div>
                  <div class="variation__content_info">
                    {{ variation.price + ' / ' + variation.weight + ' г.' }}
                  </div>
                </div>
                <img src="@/assets/delete.svg" alt="" style="cursor: pointer; height: 16px;" @click="deleteVariation(index)">
              </div>
            </div>
            <div class="flex-column d-flex gap-4 w100">
              <input v-model="newVariation.name" type="text" placeholder="Название" class="right-menu-products-list__input_value">
              <input v-model="newVariation.price" type="number"  placeholder="Цена" class="right-menu-products-list__input_value">
              <input @keydown.enter="addNewVariation" v-model="newVariation.weight" type="number"  placeholder="Вес г." class="right-menu-products-list__input_value">
            </div>
            <div class="w100 d-flex justify-center">
              <img @click="addNewVariation" style="background: #fb4619; cursor: pointer; margin-top: 8px; width: 20px; border-radius: 100px; display: flex; justify-content: center; align-items: center;" src="@/assets/add_white.svg" alt="">
            </div>
          </div>
          <div class="right-menu-products-list__input" v-if="!variationList.length">
            <div class="right-menu__content__item-title">Цена</div>
            <input v-model="selectedProduct.price" type="number" class="right-menu-products-list__input_value">
          </div>
          <div class="right-menu-products-list__input">
            <div class="right-menu__content__item-title">Количество</div>
            <input v-model="selectedProduct.in_stock" type="number" class="right-menu-products-list__input_value">
          </div>
          <template v-if="itemAttrs">
            <div class="right-menu-products-list__input" v-for="(attr, index) in itemAttrs" :key="attr.id">
              <div class="right-menu__content__item-title" >{{ attr.name === 'Калории' ? 'Калории/ кДж' : attr.name }}</div>
              <template v-if="index === 0 && attr.name === 'Состав'">
                <div class="right-menu-products-list__input-structure">
                  <div class="adedet-list" v-if="productCompositionList?.length">
                    <div class="adedet-list_atribut d-flex gap-4 align-center" v-for="(item, index) in productCompositionList" :key="index">
                      <img class="attr-composition-icon" :src="ICONS.find(i => i.value === item.iconIndex).icon" alt="" srcset="">
                        {{item.name}}
                      <img src="@/assets/delete.svg" alt="" style="cursor: pointer; height: 16px;" @click="deleteProductCompositionItem(index)">
                    </div>
                  </div>
                  <div class="d-flex w100 relative">
                    <input class="right-menu-products-list__input_value" v-model="selectedProductCompositionItemTitle" @keydown.enter="addProductCompositionItem">
                    <img @click="isOpenChoiceIconModal = !isOpenChoiceIconModal" class="composition-input-icon" :src="ICONS.find(item => item.value === selectedCompositionIcon).icon" alt="">
                  </div>
                  <img @click="addProductCompositionItem" style="background: #fb4619; cursor: pointer; margin-top: 8px; width: 20px; border-radius: 100px; display: flex; justify-content: center; align-items: center;" src="@/assets/add_white.svg" alt="">
                </div>
              </template>
              <input v-else v-model="attr.value" type="text" class="right-menu-products-list__input_value" >
            </div>
          </template>
        </template>
        
      </template>
    </right-menu>
  <base-modal :isOpen="isOpenChoiceIconModal" @close-modal="isOpenChoiceIconModal = false">
    <template #buttons><div></div></template>
    <template #header> <h5>Выберите иконку</h5> </template>
    <template #inputs>
      <div class="modal-icon-list d-flex gap-8 flex-wrap">
        <img
         class="modal-composition-icon"
         :class="[selectedCompositionIcon === item.value ? 'selected-composition-icon': '']"
          v-for="item in ICONS"
           :key="item.value"
           @click="selectCompositionIcon(item)" :src="item.icon" alt="">
      </div>
    </template>
  </base-modal>
  <base-loader :is-open="isLoading" />
</template>

<script>
import RightMenu from "@/components/RightMenu.vue";
import BaseModal from "@/components/BaseModal.vue";
import VImageInput from 'vue3-img-input';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {useProduct} from "@/composition/useProduct.js"
import draggable from "vuedraggable";
import ProductList from "@/components/Products/ProductList";
import login from "@/views/Autorization/Login";
import BaseLoader from "@/components/BaseLoader";

const {
    getProducts,
    getAttrCategory,
    attrCategoryes,
    createProduct,
    changeProduct,
    changeProductPicture,
    changeProductAttr,
    getProductById,
    products,
    categories, 
    getCategories, 
    deleteProduct
    } = useProduct()

export default {
    components:{
      BaseLoader,
      ProductList,
      draggable,
      vSelect,
      VImageInput,
      RightMenu,
      BaseModal,
    },
    data() {
        return {
            isLoading: false,
            showMessage: false,
            productList: null,
            categoryList: null,
            selectedProduct: {},
            oldProductThumbnail: null,
            oldProductPrice: null,
            itemAttrs: null,
            selectedProductCompositionItemTitle: null,
            productCompositionList: [],
            newVariation: {
                name: null,
                price: null,
            },
            variationList: [],
            categories: null,
            selectedCompositionIcon: -1,
            ICONS: [
                {value: 0, icon: require('@/assets/compositionItems/0-tortilla.svg')},
                {value: 1, icon: require('@/assets/compositionItems/1-bun.svg')},
                {value: 2, icon: require('@/assets/compositionItems/2-steak.svg')},
                {value: 3, icon: require('@/assets/compositionItems/3-chicken-breast.svg')},
                {value: 4, icon: require('@/assets/compositionItems/4-chicken-cutlet.svg')},
                {value: 5, icon: require('@/assets/compositionItems/5-fish.svg')},
                {value: 6, icon: require('@/assets/compositionItems/6-falafel.svg')},
                {value: 7, icon: require('@/assets/compositionItems/7-tomat.svg')},
                {value: 8, icon: require('@/assets/compositionItems/8-salad.svg')},
                {value: 9, icon: require('@/assets/compositionItems/9-cucumber.svg')},
                {value: 10, icon: require('@/assets/compositionItems/10-onion.svg')},
                {value: 11, icon: require('@/assets/compositionItems/11-cheese.svg')},
                {value: 12, icon: require('@/assets/compositionItems/12-pepper.svg')},
                {value: 13, icon: require('@/assets/compositionItems/13-bacon.svg')},
                {value: 14, icon: require('@/assets/compositionItems/14-egg.svg')},
                {value: 15, icon: require('@/assets/compositionItems/15-sauce.svg')},
                {value: 16, icon: require('@/assets/compositionItems/16-potato.svg')},
                {value: 17, icon: require('@/assets/compositionItems/17-cole-slow.svg')},

                {value: -1, icon: require('@/assets/compositionItems/-1-empty.svg')},
            ],
            markList: [
                {value: '0', name: "Хит"},
                {value: '1', name: "NEW"},
                {value: '2', name: "Острый"},
                {value: '3', name: "Big Size"},
                {value: '4', name: "Рыбный"},
                {value: '-1', name: "Нет метки"},
            ],
            isOpenChoiceIconModal: false,
            rightMenu: {
                isOpen: false,
                type: "",
                headerTitle: "",
                submitBtnValue: "",
            },
        }
    },
    created() {
        this.updateProductList()
    },
    methods: {
        async submitHandler() {
            switch (this.rightMenu.type) {
                case "add":
                    await this.createProduct()
                    break
                case "update":
                    await this.updateProduct()
                    break
            }
        },
        async deleteProduct(productId) {
            await deleteProduct(productId)
            this.updateProductList()
        },
        async updateProductList() {
            this.isLoading = true
            await getProducts().finally(() => {
              this.isLoading = false
            })
          this.isLoading = true
            await getCategories().finally(() => {
              this.isLoading = false
            })
            this.productList = products.value
            this.categoryList= categories.value
            this.isLoading = false


        },
        openModalAddMenu() {
            this.selectedProduct = {
                thumbnail: null,
                name: '',
                tag: {value: '-1', name: "Нет метки"}, // product markValue
                description: '', 
                price: Number(null),
                in_stock: Number(null),
                category: null,
            }
            this.rightMenu = {
                isOpen: true,
                type: "add",
                headerTitle: "Добавить продукт",
                submitBtnValue: "Добавить",
            }
        },
        addNewVariation() {
            if (this.newVariation.name && this.newVariation.price) {
                this.variationList.push({ ...this.newVariation })
                this.newVariation = {
                    name: null,
                    price: null,
                    weight: null,
                }
            }
        },
        deleteVariation(index) {
            this.variationList.splice(index, 1)
        },
        selectCompositionIcon(item) {
            this.selectedCompositionIcon = item.value;
            this.isOpenChoiceIconModal = false;
        },
        async createProduct() {
            const { thumbnail, ...productToCreate } = { ...this.selectedProduct }
            productToCreate.category = productToCreate.category.id
            productToCreate.tag = productToCreate.tag.value

            if (!productToCreate.name) {
              this.showMessage = !this.showMessage
              return
            }

            if (this.variationList.length) { 
                productToCreate.variations = JSON.stringify(this.variationList) 
            }
            await createProduct({ ...productToCreate}).then(async (r) => {
                const newProductId = r.data.data.id
                const attrIdList = [ ...r.data.data.attrs.map((attr) => attr.id) ]

                if (thumbnail) {
                    await changeProductPicture(newProductId, thumbnail)
                }
                let i = 0
                for (const attr of this.itemAttrs) {
                  if (attr?.name === "Состав" ) {
                      await changeProductAttr(attrIdList[i], {value: JSON.stringify(this.productCompositionList)})
                  } else {
                      if (attr.value) {
                          await changeProductAttr(attrIdList[i], {value: attr.value})
                      }
                  }
                      i++
                }
            })

            this.newVariation = {
                name: null,
                price: null,
                weight: null,
            }
            this.variationList = []
            this.selectedProductCompositionItemTitle = null
            this.productCompositionList = []
            this.selectedProduct = {}
            await this.updateProductList()
            this.closeRightMenu()
        },
        async updateProduct() {
            const toUpdate = {
                name: this.selectedProduct.name,
                description: this.selectedProduct.description,
                old_price: this.oldProductPrice,
                price: this.selectedProduct.price,
                tag: this.selectedProduct.tag.value,
                in_stock: this.selectedProduct.in_stock
            }

            if (!toUpdate.name) {
              this.showMessage = !this.showMessage
              return
            }

            if (this.variationList.length) { 
                toUpdate.variations = JSON.stringify(this.variationList) 
            } else {
                toUpdate.variations = ''
            }
            await changeProduct(this.selectedProduct.id, { ...toUpdate })
            if (this.oldProductThumbnail !== this.selectedProduct.thumbnail) {
                await changeProductPicture(this.selectedProduct.id, this.selectedProduct.thumbnail)
            }

            const attrIdList = [ ...this.selectedProduct.attrs.map((attr) => attr.id) ]

            let i = 0
            for (const attr of this.itemAttrs) {
                if (attr?.name === "Состав" ) {
                  this.productCompositionList.map((item) => {
                    delete item.icon
                    return item
                  })
                await changeProductAttr(attrIdList[i], {value: JSON.stringify(this.productCompositionList)})
                } else {
                if (attr.value !== null) {
                    await changeProductAttr(attrIdList[i], {value: attr.value})
                }
                }
                i++
            } 

            this.newVariation = {
                name: null,
                price: null,
                weight: null,
            }
            this.selectedProductCompositionItemTitle = null
            this.productCompositionList = []
            this.selectedProduct = {}
            await this.updateProductList()
            this.closeRightMenu()
        },
        async openModalUpdateProduct(productId) {
            this.selectedProduct = await getProductById(productId).then(r  => r.data.data)
            this.oldProductThumbnail = this.selectedProduct.thumbnail
            this.oldProductPrice = this.selectedProduct.price
            this.selectedProduct.category = this.selectedProduct.categories[0]
            this.selectedProduct.tag = this.markList.find(tag => tag.value === this.selectedProduct.tag)
            this.itemAttrs = [ ...this.selectedProduct.attrs.map(attr => { return {...attr}})  ]
            if (this.selectedProduct.attrs[0]?.name === "Состав" && this.selectedProduct.attrs[0].value) {
            this.productCompositionList = JSON.parse(this.selectedProduct.attrs[0].value)
            this.productCompositionList = this.productCompositionList.map((item) => {
                item.icon = this.ICONS.find(i => i.value === item.iconIndex).icon;
                return item
            })
            }
            if (this.selectedProduct.variations) {
                this.variationList = JSON.parse(this.selectedProduct.variations)
            }

            this.rightMenu = {
                isOpen: true,
                type: "update",
                headerTitle: "Изменить продукт",
                submitBtnValue: "Изменить",
            }
        },
        updateCategory(cat) {
            this.selectedProduct.category = {
                id: Number(cat.id),
                name: cat.name,
            }
            getAttrCategory(cat.id)
                .then(() => {
                    this.itemAttrs = attrCategoryes.value[0].attrs
                })
        },

        addProductCompositionItem(){
        if (this.selectedProductCompositionItemTitle) {
            this.productCompositionList.push({
                name: this.selectedProductCompositionItemTitle,
                iconIndex: this.selectedCompositionIcon,
            })
        }
            this.selectedProductCompositionItemTitle = null
            this.selectedCompositionIcon = -1
        },
        deleteProductCompositionItem(index){
            this.productCompositionList.splice(index, 1)
        },

        closeRightMenu(){
            this.rightMenu = {
                isOpen: false,
                type: "",
                headerTitle: "",
                submitBtnValue: "",
            }
            this.newVariation = {
                name: null,
                price: null,
                weight: null,
            }
            this.selectedProductCompositionItemTitle = null
            this.productCompositionList = []
            this.selectedProduct = {}
            this.variationList = []
        },
    },
}
</script>

<style lang="scss" >
    @import "@/styles/profile/product/RightMenuProducts.scss";
    @import "@/styles/profile/product/products.scss";
    @import "vue3-img-input/src/styles/style.scss";

    .modal-icon-list {
      max-width: 400px;
    }
    .paginator {
      display: flex;
      &__page {
        transition: background 0.3s;
        &:hover, &.active {
          background: #F2623F;
          cursor: pointer;
          font-weight: 500;
          color: white;
        }
        &_content {
          display: block;
          padding: 8px 12px;
        }
      }
    }

    .warning {
      border: 1px solid rgba(red, 0.5);
    }
</style>