<template>
<div class="right-menu-categories-list">
    <div class="right-menu-categories-list__header">
        <div class="right-menu-categories-list__header_title">Добавить категорию</div>
    </div>
    <div class="right-menu-categories-list__controls">
        <div class="right-menu-categories-list__info">
        <div class="right-menu-categories-list__input">
        <div class="right-menu-categories-list__input_title" style="margin-bottom: 10px">Выберите изображение</div>
        </div>
        <picture-input 
                ref="pictureInput"
                id="pictureInput"
                width="250" 
                height="200" 
                margin="16" 
                accept="image/jpeg,image/png" 
                size="10" 
                button-class="btn"
                :custom-strings="{
                    upload: '<h1>Bummer!</h1>',
                    drag: 'Нажмитите или перетащите изображение'
                }"
                @change="onChange">
            </picture-input>
            <div class="right-menu-categories-list__input">
                <div class="right-menu-categories-list__input_title">Имя</div>
                <input v-model="dataCategorie.name" class="right-menu-categories-list__input_value">
            </div>
        </div>
        <div class="right-menu-categories-list__buttons d-flex gap-16">
          <input type="button" class="btn btn-outline" value="Отмена" @click="addCategorie">
          <input type="button" class="btn btn-main" value="Добавить" @click="create">
        </div>
    </div>
</div>
</template>

<script>
import {useProduct} from "@/composition/useProduct.js"
import Tree from '@/components/other/Tree.vue';
import PictureInput from 'vue-picture-input'
export default {
    components:{
        Tree,
        PictureInput,
    },
    props: {
        categoriesData: Object
    },
    data () {
    return {
        dataCategorie: {
            name: "",
            parent: null,
        },
        activeParent: {
            name: 'Нет',
            id: null,
        },
        categories: null,
        treeShow: false,
        file: '',
    }
  },
    methods: {
        onChange(image) {
            this.file = this.$refs.pictureInput.file;
            if (image) {
                this.image = image
            } else {
            }
        },
        updateParentCategory(name, id){
            this.activeParent.name = name,
            this.dataCategorie.parent = id,
            this.treeShow = false
        },

        create(){
            let {createCategory, addAttrCategory, changeCategoryPicture} = useProduct()
            createCategory(this.dataCategorie)
            .then(async (r) => {
              // Добавление атрибута категории "состав"
              await addAttrCategory(r.data.data.id, {name: "Состав", optional: false})
              await addAttrCategory(r.data.data.id, {name: "Калории", optional: false})
              await addAttrCategory(r.data.data.id, {name: "Вес", optional: false})

              await changeCategoryPicture(r.data.data.id, this.file)
            .then((r) => {
                this.$emit('closeCategorie')
            })
            .finally(() => {
                this.$emit('closeCategorie')
            })
            })
        },

        addCategorie(){
            this.$emit('addCategorie')
        },
    },

    watch() {
        
    },
    beforeMount() {
        this.categories = this.$props.categoriesData
    },
}
</script>

<style lang="scss" src="@/styles/profile/product/RightMenuCategories.scss"></style>
