<template>
  <router-view></router-view>
</template>

<script>
import Autorization from "@/views/Autorization/Autorization.vue"
import Profile from "@/views/Profile/Profile.vue"
export default {
  components: {
    Autorization,
    Profile,
  },
  mounted() {
    if(localStorage.getItem('Token') == undefined || !localStorage.getItem('Token')){
      this.$router.push({name: "Login"})
      localStorage.removeItem("Token")
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600&display=swap');
@import "@/styles/app/colors.scss";
*, :after, :before {
    box-sizing: border-box;
}

* {
  transition: all 0.3s linear;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input, textarea, .autoresize-textarea {
  border: 1px solid transparent;
  box-shadow: 1px 0 2px #51515160, 0 1px 2px #51515160, -1px 0 2px #51515160, 0 -1px 2px #51515160;

  &:focus {
    outline: none;
  }
}

input::placeholder {
  text-align: left;
  font-weight: normal;
}

.router-link-exact-active {
      background: #f2623f;
      text-decoration: none;
      &:hover{
        background: #f2623f;
      }
      .menu-profile__item-child_name{
        color: #ffffff;
      }
    }

body {
  margin: 0;
  
  background: #101827;
  overflow-x: hidden;
}

#notification {
  top: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;

  .message {
    position: fixed;
    top: 2%;
    right: 2%;
    width: fit-content;
    color: $color-orange;
    background: #fff;
    font-weight: 400;
    box-shadow: 2px 2px 8px rgba(#414141, 0.5);
    padding: 12px;
    border-radius: 20px;
  }
}


li {
  list-style: none;
}

.pointer{
  cursor: pointer;
}

.reflect{
  transform: rotate(180deg);
}

div {
  font-family: 'Barlow', sans-serif;
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.gap-16 {
  gap: 16px;
}
.gap-4 {
  gap: 8px;
}
.gap-8 {
  gap: 8px;
}
.w100 {
  width: 100%;
}
.p-4 {
  padding: 4px;
}

.mb-16 {
  margin-bottom: 16px;
}

.btn {
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  transition: all 0.3s;
  font-size: 14px;
  cursor: pointer;
}
.btn-main {
  background: $color-orange-light !important;
  color: white;
  box-shadow: 2px 2px 4px #15151555 !important;

  &:hover {
    background: $color-orange !important;
    box-shadow: 2px 2px 8px rgba($color-orange-light, 0.6) !important;
  }
}

.btn-outline {
  background: white !important;
  box-shadow: 2px 2px 4px #15151555 !important;
  border: 1px solid $color-orange;

  &:hover {
    background: $color-orange !important;
    color: white;
    box-shadow: 2px 2px 8px rgba($color-orange-light, 0.6) !important;
  }
}

.input-color {
  cursor: pointer;
  position: relative;

  .color-preview {
    width: 18px;
    height: 18px;
  }

  &-picker {
    position: absolute;
    top: 60px;
    right: 0;

    .colors {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .item {
        margin: 0;
      }
    }
  }
  &-icon {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 8px;
    color: $color-orange;
    height: 100%;
    right: 0;
    top: 0;
  }

  &:hover {
    box-shadow: 0 2px 4px rgba($color-orange-light, 0.6) !important;
    outline: 0;
  }
}

.pointer {
  cursor: pointer;
}

textarea {
  resize: none;
}
textarea:focus  {
  outline: 0;
}

.v-select{
  width: 100%;
  max-height: 30px;
  box-shadow: 2px 2px 4px #51515140;

  &:hover, &:focus {
    --vs-border-color: #F2623FFF !important;
  }
  .vs__selected-options {
    overflow: hidden;
  }
  span.vs__selected {
    max-width: 250px;
    max-height: 25px;
    overflow-x: ellipsis;
    white-space: nowrap;
  }

  .vs__selected, .vs__search, .vs__dropdown-toggle{
    cursor: pointer;
  }
  .vs__selected, .vs__dropdown-toggle, .vs__selected-options {
  }
  .vs__selected, .vs__search {
    margin-top: 0;
  }
  .vs__dropdown-option {
    padding-left: 5px;
  }
  .vs__fade-leave-to {
    transition: all 0.5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    transform: translateY(100px);
  }
  .vs__fade-enter-from {
    transform: translateY(-10px);
    opacity: 0.2;
  }
  .vs__fade-enter-to{
    transition: all 0.3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
}
.vs__dropdown-option--selected {
  background: $color-orange !important;
  color: white !important;
}
:root {
  --vs-controls-color: #ee5129 !important;

  --vs-dropdown-bg: #fff !important;
  --vs-dropdown-color: #ee5129 !important;
  --vs-dropdown-option-color: #515151 !important;

  --vs-selected-bg: #ee5129 !important;
  --vs-selected-color: #515151 !important;

  --vs-search-input-color: #000 !important;

  --vs-dropdown-option--active-bg: #F2623F !important;
  --vs-dropdown-option--active-color: #fff !important;
}


.height-enter-active,
.height-leave-active {
  transition: all 0.5s;
  max-height: 230px;
}
.height-enter,
.height-leave-to
{
  opacity: 0;
  max-height: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.image-wrapper120 {
    width: 120px;
    height: 120px;
}

.image-wrapper100perOn100px {
    width: 100%;
    height: 100px;
}
</style>
