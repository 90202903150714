
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useAdmin() {

    const { api, params, store_id } = useApi()

    const getProfile = () => {
       return axios.get(api + `/cp/profile/`, params)
    }

    const putProfileName = (payload) => {
        return axios.put(api + `/cp/profile/name/`, payload,params)
    }

    const putProfileEmail = (payload) => {
        return axios.put(api + `/cp/profile/email/`, payload, params)
    }

    const sendEmailCode = (targetType, payload) => {
        return axios.post(api + `/store/${store_id.value}/verifications/${targetType}/`, payload, params)
    }

    return {
        getProfile,
        putProfileName,
        putProfileEmail,
        sendEmailCode,
    }
}