<template>
  <!-- <div class="tree">
    <ul class="tree-list">
      
    </ul>
    
  </div> -->
  <node-tree @updateParentCategory="updateParentCategory" class="tree-list__item" :node="treeData"></node-tree>
</template>

<script>
  import NodeTree from '@/components/other/NodeTree.vue';

  export default {
    props: {
      treeData: Object
    },
    components: {
      NodeTree
    },
    methods: {
        updateParentCategory(name, id){
            this.$emit('updateParentCategory', name, id)
        }
    },
  };
</script>

<style lang="scss">
  .tree-list {
    &__item{
      color: #FFFFFF;
      background: #333;
      padding: 10px;
      cursor: pointer;
    }
  }
</style>