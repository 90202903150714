<template>
    <div class="contacts">
        <div class="contacts__header">
            <div class="contacts__header_title">Контакты</div>
<!--            <div class="contacts__header_button pointer" @click="addedetNew = !addedetNew">Добавить-->
<!--                <img src="@/assets/add_box_white.svg" alt="">-->
<!--            </div>-->
        </div>
        <div class="contacts__main">
            <template v-for="(contact, index) in contacts" :key="index">
                <div class="contacts__main-item">
                    <input class="contacts__main-item_name" disabled v-model="contact.name">
                    <input class="contacts__main-item_value" v-model="contact.text">
                    <div class="contacts__buttons">
                        <div class="contacts__buttons_change" @click="contactChange(contact)">Изменить</div>
<!--                        <img @click="contactDelete(contact)" style="background: #FFFFFF; border-top: 1px solid #F2623F; padding: 2.3px 2.8px; border-radius: 0px" class="pointer" src="@/assets/delete-red.svg" alt="">-->
                    </div>
                </div>
            </template>
            <div class="contacts__main-item" v-if="addedetNew">
                <input class="contacts__main-item_name" v-model="newContact.name">
                <input class="contacts__main-item_value" v-model="newContact.text">
                 <div class="contacts__buttons">
                    <div class="contacts__buttons_change" @click="addedetContact">Добавить</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted} from "vue"

import {useContacts} from "@/composition/useContacts.js"
export default {
    setup(props, {emit}) {
        const {getContacts, contacts, putContact, createContact, deleteContact} = useContacts()

        onMounted(() => {
            getContacts()
        })

        const newContact = ref({
            name: "",
            text: "",
            link: "",
        })

        const addedetNew = ref(false)

        const contactChange = (contact) => {
            putContact({
                name: contact.name,
                text: contact.text,
            }, contact.id)
        }

        const addedetContact = () => {
            createContact(newContact.value)
            .then(() => {
                newContact.value.name = ""
                newContact.value.text = ""
                newContact.value.link = ""

                getContacts()
            })
        }

        const contactDelete = (contact) => {
            deleteContact(contact.id)
            .then(() => {
                 getContacts()
            })
        }
        
        return{
            contacts,
            contactChange,
            addedetContact,
            createContact,
            contactDelete,

            newContact,

            addedetNew,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/informations/contacts.scss"></style>

