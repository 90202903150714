<template>
  <div
      class="right-menu space-between"
      :style="{'width': width, 'margin-right': currentMarginRight}">
    <div class="flex-column">
      <div class="right-menu__header">
        <div class="right-menu__header-title"><slot name="header"></slot></div>
      </div>

      <div class="right-menu__content">
        <slot name="content"></slot>
      </div>
    </div>

      <div class="right-menu__actions d-flex gap-16">
        <slot name="actions">
          <input type="button" class="btn btn-outline" value="Отмена" @click="closeMenu">
          <input type="button" class="btn btn-main" :value="submitBtnValue" @click="submitHandler">
        </slot>
      </div>

    <teleport to="#notification">
      <div v-if="isShowMessage" class="message">
        Обязательные поля не были заполены
      </div>
    </teleport>
  </div>
</template>

<script>
export default {
  name: "RightMenu",
  emits: ['close-menu', 'submit'],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    submitBtnValue: {
      type: String,
      required: false,
      default: "Добавить",
    },
    width: {
      type: String,
      required: false,
      default: '400px',
    },
    showMessage: Boolean,
  },
  data() {
    return {
      isShowMessage: false,
    }
  },
  computed: {
    currentMarginRight() {
      return !this.isOpen ? "-" + this.width : "0px"
    }
  },
  methods: {
    closeMenu() {
      this.$emit("close-menu")
    },
    submitHandler() {
      this.$emit("submit")
    },
  },
  watch: {
    showMessage() {
      this.isShowMessage = true
      setTimeout(() => {
        this.isShowMessage = false
      }, 3000)
    }
  }

}
</script>

<style lang="scss">
@import "@/styles/app/colors.scss";
.right-menu {
  display: flex;
  background: white;
  height: calc(100vh - 52px);
  align-items: flex-start;
  flex-direction: column;
  border-left: 4px solid $color-orange-light;
  position: fixed;
  right: 0;
  overflow-y: auto;
  margin-top: 52px;
  top: 0;

  &__actions{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 32px 24px;

  }
  .flex-column {
    width: 100%;
  }
  &__header{
    width: 100%;
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-title{
      font-size: 22px;
      font-weight: 700;
    }
  }
  &__content {
    display: flex;
    padding: 0 40px 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    &__item {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      & + & {
        margin-top: 16px;
      }

      &-title {
        margin-bottom: 8px;
        font-weight: bold;

        &.required {
          position: relative;
          &:after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $color-orange;
            position: absolute;
            top: 0;
            right: -12px;
          }
        }
      }
      .autoresize-textarea, &-input, &-textarea {
        &:focus, &:hover{
          box-shadow: 2px 2px 4px rgba($color-orange-light, 0.6), -1px -1px 2px rgba($color-orange-light, 0.6) !important;
          border: 1px solid rgba($color-orange-light, 0.6);
          outline: 0;
        }
      }
      &-textarea  {
        width: 100%;
        height: 100px;
        padding: 4px;
        border: 1px solid transparent;
        font-size: 16px;
      }
      .autoresize-textarea {
        border: 1px solid transparent;
        font-size: 16px;
        padding: 4px;
      }
      &-input {
        width: 100%;
        height: 28px;
        padding: 8px;
        background: none;

        &::placeholder {
          text-align: left;
          font-weight: normal;
        }
      }
    }
  }
}

.dp__theme_light {
  --dp-hover-color: rgba(238, 81, 38, 0.15);
  --dp-primary-color: #ee5129;
}

.image-input{
    .wrapper {

        .input-label {
            border: 2px #bbb dashed;
            border-radius: 5px;

            &:hover {
              opacity: 0.8 !important;
                color: #F2623F;
                border-color: #F2623F;
            }

            .selected-image {
                border-radius: 5px;
                opacity: 0.8 !important;
            }
        }
    }
    .is-drag-over {
        color: #F2623F;
        border-color: #F2623F !important;
    }
}

</style>