
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useProduct() {

    const { api, params, Token, store_id } = useApi()


    const categories = ref()
    const products = ref()
    const totalPages = ref()
    const attrCategoryes = ref("FUCKING")

    const getCategories = () => {
       return axios.get(api + `/cp/stores/${store_id.value}/categories/`, params)
       .then((r) => {
           categories.value = [ ...r.data.data ]
       })
    }
    const createCategory = (dataCategorie) => {
        return axios.post(api + `/cp/stores/${store_id.value}/categories/`, dataCategorie, params)
    }
    const deleteCategory = (id_category) => {
        return axios.delete(api + `/cp/categories/${id_category}/`, params)
    }
    const changeCategory = (id_category, dataCategorie) => {
        return axios.put(api + `/cp/categories/${id_category}/`, dataCategorie, params)
    }
    const reorderCategories = (idList) => {
        return axios.put(api + `/cp/categories/order/`, idList, params)
    }
    const changeCategoryPicture = (id_category, image ) => {
        const formData = new FormData()
        formData.append('img',  image)
        const config = {
            headers: {
                "Authorization": Token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.put(api + `/cp/categories/${id_category}/thumbnail/`, formData, config)
    }

    const getAttrCategory = (category_id) => {
        return axios.get(api + `/cp/categories/${category_id}/attrs/`, params)
        .then((r) => {
            attrCategoryes.value = r.data.data
        })
    }
    const addAttrCategory = (category_id, body) => {
        return axios.post(api + `/cp/categories/${category_id}/attrs/`, body, params)
    }
    const deleteAttrCategory = (attr_id) => {
        return axios.delete(api + `/cp/categories/attrs/${attr_id}/`, params)
    }
    const changeAttrCategory = (attr_id, body) => {
        return axios.put(api + `/cp/categories/attrs/${attr_id}/`, body, params)
    }

    const getProducts = (page = 1) => {
        return axios.get(api + `/cp/stores/${store_id.value}/products/?category=&page=${page}`, params)
            .then((r) => {
                products.value = [ ...r.data.data ]
            })
    }
    const reorderProducts = (idList) => {
        return axios.put(api + `/cp/products/order/`, idList, params)
    }
    const getProductById = (productId) => {
        return axios.get(api+ `/cp/products/${productId}/`, params)
    }
    const createProduct = (body) => {
        return axios.post(api + `/cp/products/`, body, params)
    }
    const changeProduct = (productId, payload) => {
        return axios.put(api+ `/cp/products/${productId}/`, payload, params)
    }
    const changeProductPicture = (productId, image) => {
        const formData = new FormData()
        formData.append('img',  image)
        const config = {
            headers: {
                "Authorization": Token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.put(api+ `/cp/products/${productId}/thumbnail/`, formData, config)
    }
    const deleteProduct = (product_id) => {
        return axios.delete(api + `/cp/products/${product_id}/`, params)
    }

    const changeProductAttr = (attr_id, payload) => {
        return axios.put(api + `/cp/products/attrs/${attr_id}/`, payload, params )
    }


    return {
        getCategories,
        createCategory,
        deleteCategory,
        changeCategory,
        changeCategoryPicture,
        categories,
        reorderCategories,

        getAttrCategory,
        addAttrCategory,
        deleteAttrCategory,
        changeAttrCategory,
        attrCategoryes,

        getProducts,
        getProductById,
        createProduct,
        deleteProduct,
        changeProduct,
        changeProductPicture,
        totalPages,
        products,
        reorderProducts,

        changeProductAttr,
    }
}