<template>
    <span class="label pointer" @click="updateParentCategory(node.name, node.id)">{{ node.name }}</span>
    <template v-if="node.subcategories && node.subcategories.length">
        <node class="node-tree__item" v-for="(child, index) in node.subcategories" :key="index" :node="child"  @click="updateParentCategory(child.name, child.id)"></node>
    </template>
</template>

<script>
  export default {
    name: 'node',
    props: {
      node: Object
    },

    methods: {
        updateParentCategory(name, id){
            this.$emit('updateParentCategory', name, id)
        }
    },
  };
</script>

<style lang="scss">
    .label{
        background: #333;
        color: #FFFFFF;
        width: 100%;
        text-align: left;
        padding: 10px;
        &:hover{
            background: #FFFFFF;
            color: #333;
        }
    }
    .node-tree__item{
            color: #FFFFFF;
            background: #333;
            cursor: pointer;
            &:hover{
                background: #FFFFFF;
                color: #333;
            }
        }
</style>