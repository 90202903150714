import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/autorization', name: 'Autorization', redirect: { name: 'Login' }, component: () => import('../views/Autorization/Autorization.vue'),
    children: [
      {
        path: '/autorization/login', name: 'Login', component: () => import('../views/Autorization/Login.vue'),
      },
      {
        path: '/autorization/recover', name: 'Recover', component: () => import('../views/Autorization/Recover.vue'),
      },
    ]
  },
  {
    path: '/profile', name: 'Profile', redirect: { name: 'Banners' }, component: () => import('../views/Profile/Profile.vue'),
    children: [
      {
        path: '/profile/admin', name: 'Admin', component: () => import('../views/Profile/Admin.vue'),
      },
      {
        path: '/profile/product', name: 'Product', redirect: { name: 'Products' }, component: () => import('../views/Profile/Products/Product.vue'),
        children: [
          {
            path: '/profile/product/products', name: 'Products', component: () => import('../views/Profile/Products/Products.vue'),
          },
          {
            path: '/profile/product/categories', name: 'Categories', component: () => import('../views/Profile/Products/Categories.vue'), props: true,
          },
        ]
      },
      {
        path: '/profile/stock', name: 'Stock', redirect: { name: 'Banners' }, component: () => import('../views/Profile/Stock/Stock.vue'),
        children: [
          {
            path: '/profile/stock/baners', name: 'Banners', component: () => import('../views/Profile/Stock/Baners.vue'),
          },
        ]
      },
      {
        path: '/profile/informations', name: 'Informations', redirect: { name: 'Pages' }, component: () => import('../views/Profile/Informations/Informations.vue'),
        children: [
          {
            path: '/profile/informations/partners', name: 'Partners', component: () => import('../views/Profile/Informations/PartnersView.vue'),
          },
          {
            path: '/profile/informations/acts', name: 'Acts', component: () => import('../views/Profile/Informations/ActionsView.vue'),
          },
          {
            path: '/profile/informations/managers', name: 'Managers', component: () => import('../views/Profile/Informations/ManagersView.vue'),
          },
          {
            path: '/profile/informations/principles', name: 'Principles', component: () => import('../views/Profile/Informations/PrinciplesView.vue'),
          },
          {
            path: '/profile/informations/pages', name: 'Pages', component: () => import('../views/Profile/Informations/Pages.vue'),
          },
          {
            path: '/profile/informations/contacts', name: 'Contacts', component: () => import('../views/Profile/Informations/Contacts.vue'),
          },
        ]
      },
      {
        path: '/profile/locations', name: 'Locations', redirect: { name: 'Products' }, component: () => import('../views/Profile/Locations/Locations.vue'),
        children: [
          {
            path: '/profile/locations/cities', name: 'Cities', component: () => import('../views/Profile/Locations/Cities.vue'),
          },
          {
            path: '/profile/locations/points', name: 'Points', component: () => import('../views/Profile/Locations/Points.vue'),
          },
        ]
      },
      {
        path: '/profile/subscriptions', name: 'Subscriptions', component: () => import('../views/Profile/Subscriptions/Subscriptions.vue'),
        /* children: [
          {
            path: '/profile/subscriptions/purchasing', name: 'Purchasing', component: () => import('../views/Profile/Subscriptions/Purchasing.vue'), meta: { title: 'NotAlone - Пользователи', link: "Покупка" }
          },
        ] */
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    const token = localStorage.getItem('Token')
    if (token) {
      return next({name: 'Banners'})
    }  else {
      return next({name: "Login"})
    }
  }
  next()

})

export default router
