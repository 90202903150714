<template>
    <div class="pages">
        <div class="pages__header">
            <div class="pages__header_title">Страницы</div>
        </div>
        <div class="pages__main">
            <div class="pages__main-item">
                <input class="pages__main-item_name" v-model="dataPage.name">
                <textarea class="pages__main-item_body" name="" id="" cols="30" rows="10" v-model="dataPage.body"></textarea>
            </div>
        </div>
        <div class="pages__buttons">
            <div class="pages__buttons_change" @click="changePage">Изменить</div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, watch} from "vue"

import {usePages} from "@/composition/usePages.js"
export default {
    components: {
    },
    setup(props, {emit}) {
        const {getPage, page, putPage} = usePages()

        const dataPage = ref({
            name: "",
            id: null,
            body: "",
        })
        onMounted(async () => {
            /* getPages() */

            await getPage(1)
            .then((r) => {
                dataPage.value.name = page.value.name
                dataPage.value.id = page.value.id
                dataPage.value.body = page.value.body
            })
        })

        const changePage = async () => {
            await putPage({
                name: dataPage.value.name,
                body: dataPage.value.body,
            }, dataPage.value.id)
        }
        
        return{
            /* pages, */
            page,
            dataPage,
            changePage,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/informations/pages.scss"></style>

