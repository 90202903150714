
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useSubscriptions() {

    const { api, params } = useApi()
    const subscriptions = ref()
    const getSubscriptions = () => {
       return axios.get(api + `/api/v1/groups/`, params)
       .then((r) => {
        groups.value = r.data.data
       })
    }

    return {
        getSubscriptions,
        subscriptions,
    }
}