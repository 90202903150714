<template>
    <div class="categories">
        <div class="categories__header">
            <div class="categories__header_title">Категории</div>
            <div class="categories__header_button pointer" @click="addCategorie">Добавить
                <img src="@/assets/add_box_white.svg" alt="">
            </div>
        </div>
        <CategoriesList :categoriesData="categoriesData" @open-change-menu="openChangeMenu"/>

      <template v-if="categoriesData && menu_active">
        <RightMenuCategories
          class="right-menu-categories"
          :class="{'right-menu-categories__active': menu_active}"
          @closeCategorie="closeCategorie"
          @addCategorie="addCategorie"
          :category-list="categoriesData"/>
      </template>
      <template v-if="categoryToChange?.id && isOpenChangeMenu">
        <RightMenuCategoriesChanged
            class="right-menu-categories"
            :class="{'right-menu-categories__active': isOpenChangeMenu}"
            @close-change-menu="closeChangeMenu"
            @update-category="updateCategoryList"
            :category-data="categoryToChange"/>
      </template>
    </div>
</template>

<script>
import {ref, onMounted} from "vue"
import CategoriesList from "@/components/Products/CategoriesList.vue"
import RightMenuCategories from "@/components/Products/RightMenuCategories.vue"
import RightMenuCategoriesChanged from "@/components/Products/RightMenuCategoriesChanged.vue"
import {useProduct} from "@/composition/useProduct.js"
export default {
    components: {
        CategoriesList,
        RightMenuCategories,
        RightMenuCategoriesChanged,
    },

    setup(props, {emit}) {
        const {getCategories, categories} = useProduct()

        const menu_active = ref(false)
        const addCategorie = () => {
            menu_active.value = !menu_active.value
        }
        const closeCategorie = () => {
            menu_active.value = false
            getCategories()
            .then(() => {
                categoriesData.value = categories.value
            })
        }


      const categoryToChange = ref(null)
      const isOpenChangeMenu = ref(null)
      const openChangeMenu = (category) => {
        categoryToChange.value = { ...category }
        isOpenChangeMenu.value = true
      }
      const closeChangeMenu = () => {
        isOpenChangeMenu.value = false
      }
      const updateCategoryList = () => {
        getCategories()
            .then(() => {
              categoriesData.value = categories.value
            })
      }

        const categoriesData = ref()

        onMounted(() => {
            getCategories()
            .then(() => {
                categoriesData.value = categories.value
            })
        })
        return{
            categories,

            menu_active,
            addCategorie,
            closeCategorie,
            categoriesData,

            isOpenChangeMenu,
            categoryToChange,
            openChangeMenu,
            closeChangeMenu,
            updateCategoryList,

        }
    }
}
</script>

<style lang="scss">
  @import '@/styles/profile/product/categories.scss';
  @import "vue3-img-input/src/styles/style.scss";
</style>

