
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useActions() {

    const { api, params, Token } = useApi()

    const actions = ref()

    const getActions = () => {
        return axios.get(api + `/cp/actions/`, params)
            .then((r) => {
                actions.value = [ ...r.data.data ]
            })
    }
    const createAction = (payload) => {
        return axios.post(api + `/cp/actions/`, payload, params)
    }
    const changeActionImg = (id, img) => {
        const formData = new FormData()
        formData.append('img',  img)
        const config = {
            headers: {
                "Authorization": Token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.put(api + `/cp/actions/${id}/img/`, formData, config)
    }

    const updateActionById = (id, payload) => {
        return axios.put(api + `/cp/actions/${id}/`, payload, params)
    }
    const reorderActions = (payload) => {
        return axios.put(api + `/cp/actions/order/`, payload, params)
    }

    return {
        getActions,
        createAction,
        updateActionById,
        changeActionImg,
        reorderActions,
        actions,
    }
}